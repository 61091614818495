import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  IconButton, 
  Typography, 
  Box, 
  TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(128, 128, 128, 0.3)',
    backdropFilter: 'blur(12px)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '12px',
    padding: '20px',
    color: 'white',
    width: '80vw',
    maxWidth: '800px',
    maxHeight: '90vh',
  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '8px',
  padding: '16px',
  marginTop: '16px',
  marginBottom: '16px',
}));

const Highlight = styled('span')(({ theme, type }) => ({
  padding: '2px 4px',
  borderRadius: '4px',
  fontWeight: 'bold',
  ...(type === 'name' && {
    backgroundColor: 'rgba(255, 193, 7, 0.2)', // Amarillo para nombres
    color: '#ffc107',
  }),
  ...(type === 'tech' && {
    backgroundColor: 'rgba(33, 150, 243, 0.2)', // Azul para términos técnicos
    color: '#2196f3',
  }),
  ...(type === 'situation' && {
    backgroundColor: 'rgba(76, 175, 80, 0.2)', // Verde para situaciones
    color: '#4caf50',
  }),
}));

const processText = (text) => {
  const parts = [];
  let lastIndex = 0;

  // Expresiones regulares para detectar diferentes tipos de contenido
  const patterns = [
    { regex: /\b([A-Z][a-z]+ [A-Z][a-z]+)\b/g, type: 'name' }, // Nombres propios
    { regex: /\b([A-Z]{2,}|[A-Z][a-z]+(?:[A-Z][a-z]+)+)\b/g, type: 'tech' }, // Términos técnicos o acrónimos
    { regex: /\b(crear|desarrollar|implementar|diseñar|innovar)\b/gi, type: 'situation' }, // Palabras clave de situaciones
  ];

  patterns.forEach(({ regex, type }) => {
    const matches = [...text.matchAll(regex)];
    matches.forEach((match) => {
      const [fullMatch] = match;
      const startIndex = match.index;
      const endIndex = startIndex + fullMatch.length;

      if (startIndex > lastIndex) {
        parts.push(text.slice(lastIndex, startIndex));
      }

      parts.push(<Highlight key={startIndex} type={type}>{fullMatch}</Highlight>);
      lastIndex = endIndex;
    });
  });

  if (lastIndex < text.length) {
    parts.push(text.slice(lastIndex));
  }

  return parts;
};

const NoteViewDialog = ({ open, note, onClose, onSave, onCopy }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState('');

  useEffect(() => {
    if (note) {
      setEditedContent(note.summary);
    }
  }, [note]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    onSave(note.timestamp, editedContent);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedContent(note?.summary || '');
    setIsEditing(false);
  };

  if (!note) return null;

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="note-dialog-title"
    >
      <DialogTitle id="note-dialog-title">
        {isEditing ? 'Editar Nota' : (note.title || 'Sin título')}
        <Box sx={{ position: 'absolute', right: 8, top: 8 }}>
          {!isEditing && (
            <>
              <IconButton onClick={handleEdit} size="small" sx={{ color: 'white', mr: 1 }}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => onCopy(note)} size="small" sx={{ color: 'white' }}>
                <ContentCopyIcon />
              </IconButton>
            </>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        {isEditing ? (
          <TextField
            autoFocus
            margin="dense"
            id="note-content"
            label="Contenido de la nota"
            type="text"
            fullWidth
            multiline
            rows={10}
            variant="outlined"
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
                '&:hover fieldset': { borderColor: 'white' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
              '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
              '& .MuiInputBase-input': { color: 'white' },
            }}
          />
        ) : (
          <>
            <ContentBox>
              <Typography variant="body1" paragraph sx={{ whiteSpace: 'pre-wrap', lineHeight: 1.6 }}>
                {processText(note.summary)}
              </Typography>
            </ContentBox>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {isEditing ? (
          <>
            <Button onClick={handleCancel} sx={{ color: 'white' }}>
              Cancelar
            </Button>
            <Button onClick={handleSave} sx={{ color: 'white' }}>
              Guardar
            </Button>
          </>
        ) : (
          <Button onClick={onClose} sx={{ color: 'white' }}>
            Cerrar
          </Button>
        )}
      </DialogActions>
    </StyledDialog>
  );
};

export default NoteViewDialog;