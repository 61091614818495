import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';

const phrases = [
  "Thinking big...",
  "Going beyond...",
  "Magic unfolding...",
  "Unleashing potential...",
  "Crafting brilliance...",
  "Mind in motion...",
  "Perfecting now...",
  "Igniting ideas...",
  "Pushing boundaries...",
  "Weaving wonders...",
  "Sculpting solutions...",
  "Forging the future...",
  "Sparking innovation...",
  "Elevating thoughts...",
  "Expanding horizons...",
];

const glowAnimation = keyframes`
  0% { opacity: 0; filter: blur(10px); }
  50% { opacity: 1; filter: blur(0px); }
  100% { opacity: 0; filter: blur(10px); }
`;

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const CircleIcon = styled('div')(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: '50%',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: theme.spacing(2),
}));

const AnimatedText = styled(Typography)(({ theme }) => ({
  animation: `${glowAnimation} 3s infinite`,
}));

const ThinkingIcon = () => {
  const [currentPhrase, setCurrentPhrase] = useState(phrases[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * phrases.length);
      setCurrentPhrase(phrases[randomIndex]);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <IconContainer>
      <CircleIcon>
        <img src="/images/Icon/AGT.svg" alt="AGT Icon" width="24" height="24" />
      </CircleIcon>
      <AnimatedText variant="body1">
        {currentPhrase}
      </AnimatedText>
    </IconContainer>
  );
};

export default ThinkingIcon;