import React, { useState, useCallback, memo } from 'react';
import { TextareaAutosize, IconButton, Box } from '@mui/material';
import { ArrowUp } from 'lucide-react';
import { styled } from '@mui/material/styles';

const StyledTextarea = styled(TextareaAutosize)({
  flexGrow: 1,
  backgroundColor: 'transparent',
  border: 'none',
  color: 'white',
  resize: 'none',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  padding: '10px 0',
  '&:focus': {
    outline: 'none',
  },
  '&::placeholder': {
    color: 'rgba(255, 255, 255, 0.5)',
  },
});

const OptimizedInput = memo(({ onSendMessage, isWaitingForResponse, inputRef }) => {
    const [inputMessage, setInputMessage] = useState('');
  
    const handleInputChange = useCallback((e) => {
      setInputMessage(e.target.value);
    }, []);
  
    const handleKeyPress = useCallback((event) => {
      if (event.key === 'Enter' && !event.shiftKey && !isWaitingForResponse) {
        event.preventDefault();
        onSendMessage(inputMessage);
        setInputMessage('');
      }
    }, [onSendMessage, isWaitingForResponse, inputMessage]);
  
    const handleSendClick = useCallback(() => {
      if (inputMessage.trim() && !isWaitingForResponse) {
        onSendMessage(inputMessage);
        setInputMessage('');
      }
    }, [onSendMessage, inputMessage, isWaitingForResponse]);
  
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <StyledTextarea
          ref={inputRef}
          placeholder={isWaitingForResponse ? "Esperando respuesta..." : "Escribe un mensaje..."}
          value={inputMessage}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          minRows={1}
          maxRows={4}
          disabled={isWaitingForResponse}
        />
        <IconButton 
          onClick={handleSendClick} 
          disabled={isWaitingForResponse || !inputMessage.trim()}
          sx={{ color: 'white', marginLeft: '8px' }}
        >
          <ArrowUp />
        </IconButton>
      </Box>
    );
  });
  
  export default OptimizedInput;