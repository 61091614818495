import React, { useEffect, useCallback, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const SEARCH_ENGINE_ID = process.env.REACT_APP_SEARCH_ENGINE_ID;
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const SearchContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: theme.spacing(2),
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  borderRadius: theme.shape.borderRadius,
}));

const InlineImageSearch = ({ onImageSelect, initialQuery }) => {
  const [hasSearched, setHasSearched] = useState(false);

  const searchImages = useCallback(async (searchQuery) => {
    try {
      const url = new URL('https://www.googleapis.com/customsearch/v1');
      url.searchParams.append('key', API_KEY);
      url.searchParams.append('cx', SEARCH_ENGINE_ID);
      url.searchParams.append('q', searchQuery);
      url.searchParams.append('searchType', 'image');
      url.searchParams.append('num', '1');

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to fetch results');
      }
      const data = await response.json();
      return data.items || [];
    } catch (err) {
      console.error('Error fetching images:', err);
      return [];
    }
  }, []);

  useEffect(() => {
    const fetchAndSelectImage = async () => {
      if (initialQuery && !hasSearched) {
        const images = await searchImages(initialQuery);
        if (images.length > 0) {
          onImageSelect(images[0]);
        }
        setHasSearched(true);
      }
    };

    fetchAndSelectImage();
  }, [initialQuery, searchImages, onImageSelect, hasSearched]);

  if (hasSearched) {
    return null;
  }

  return (
    <SearchContainer>
      <CircularProgress size={24} />
      <Typography>Buscando imagen...</Typography>
    </SearchContainer>
  );
};

export default InlineImageSearch;