import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { 
  Box, 
  IconButton, 
  Tooltip, 
  Typography,
  Popover,
  MenuItem,
  Slider,
  CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Document, Packer, Paragraph, TextRun, HeadingLevel, AlignmentType } from 'docx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import 'katex/dist/katex.min.css';
import { BlockMath, InlineMath } from 'react-katex';
import mermaid from 'mermaid';

const AGTMaker = ({ content, onDelete, isOpen, onClose, onContentReady = () => {} }) => {
  const [displayedContent, setDisplayedContent] = useState('');
  const [visibleContent, setVisibleContent] = useState('');
  const [isContentReady, setIsContentReady] = useState(false);
  const [styleMode, setStyleMode] = useState('grid');
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [showZoomControls, setShowZoomControls] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [panOffset, setPanOffset] = useState({ x: 0, y: 0 });
  const [isPanning, setIsPanning] = useState(false);
  const [hasContent, setHasContent] = useState(false);
  const [mermaidSvgs, setMermaidSvgs] = useState({});
  const [isScrolling, setIsScrolling] = useState(false);
  const contentRef = useRef(null);
  const lastScrollTopRef = useRef(0);

  const colors = useMemo(() => ({
    bg: '#FFFFFF',
    cream: '#F5F5F5',
    line: '#E0E0E0',
    text: '#000000'
  }), []);

  useEffect(() => {
    mermaid.initialize({ 
      startOnLoad: false,
      theme: 'default',
      securityLevel: 'loose',
    });
  }, []);

  useEffect(() => {
    if (isOpen) {
      const agtContent = extractAGTContent(content);
      setDisplayedContent(agtContent);
      setVisibleContent('');
      setIsContentReady(false);
      setHasContent(agtContent.trim().length > 0);
      renderMermaidDiagrams(agtContent);
    }
  }, [isOpen, content]);

  useEffect(() => {
    if (displayedContent && !isContentReady) {
      const cleanup = revealContentGradually(displayedContent);
      return cleanup;
    }
  }, [displayedContent, isContentReady]);

  useEffect(() => {
    if (contentRef.current) {
      const handleScroll = () => {
        setIsScrolling(true);
        clearTimeout(contentRef.current.scrollTimer);
        contentRef.current.scrollTimer = setTimeout(() => {
          setIsScrolling(false);
        }, 100);
      };

      contentRef.current.addEventListener('scroll', handleScroll);
      return () => {
        if (contentRef.current) {
          contentRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (contentRef.current && !isScrolling) {
      const { scrollHeight, clientHeight, scrollTop } = contentRef.current;
      const isScrolledToBottom = scrollHeight - clientHeight <= scrollTop + 1;

      if (isScrolledToBottom || scrollTop < lastScrollTopRef.current) {
        contentRef.current.scrollTop = scrollHeight;
        lastScrollTopRef.current = scrollHeight;
      }
    }
  }, [visibleContent, isScrolling]);

  const extractAGTContent = useCallback((text) => {
    const agtRegex = /<agt>([\s\S]*?)<\/agt>/g;
    const matches = text.match(agtRegex);
    if (matches) {
      return matches.map(match => match.replace(/<\/?agt>/g, '')).join('\n\n');
    }
    return '';
  }, []);

  const renderMermaidDiagrams = useCallback(async (text) => {
    const mermaidRegex = /```mermaid([\s\S]*?)```/g;
    const matches = text.matchAll(mermaidRegex);
    const newSvgs = {};
    let index = 0;
    for (const match of matches) {
      let mermaidCode = match[1].trim();
      mermaidCode = mermaidCode.replace(/^graph\s+[TDLR];\s*\n?/i, '');
      try {
        const { svg } = await mermaid.render(`mermaid-${index}`, mermaidCode);
        newSvgs[index] = svg;
        index++;
      } catch (error) {
        console.error('Error rendering Mermaid diagram:', error);
        newSvgs[index] = `<div>Error rendering diagram</div>`;
        index++;
      }
    }
    setMermaidSvgs(newSvgs);
    setShowZoomControls(Object.keys(newSvgs).length > 0);
  }, []);

  const revealContentGradually = useCallback((fullContent) => {
    const words = fullContent.split(' ');
    let currentText = '';
    let wordIndex = 0;

    const revealInterval = setInterval(() => {
      if (wordIndex < words.length) {
        currentText += (wordIndex > 0 ? ' ' : '') + words[wordIndex];
        setVisibleContent(prev => prev + (wordIndex > 0 ? ' ' : '') + words[wordIndex]);
        wordIndex++;
      } else {
        clearInterval(revealInterval);
        setIsContentReady(true);
        onContentReady();
      }
    }, 10);

    return () => clearInterval(revealInterval);
  }, [onContentReady]);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(displayedContent);
  }, [displayedContent]);

  const handleDownloadClick = useCallback((event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setMenuPosition({
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setAnchorEl(event.currentTarget);
  }, []);

  const handleDownloadClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleDownloadTxt = useCallback(() => {
    const blob = new Blob([displayedContent], { type: 'text/plain' });
    saveAs(blob, 'agt-maker-content.txt');
    handleDownloadClose();
  }, [displayedContent, handleDownloadClose]);

  const handleDownloadPdf = useCallback(async () => {
    const pdf = new jsPDF();
    const lines = displayedContent.split('\n');
    let y = 20;
    const margin = 15;
    const pageWidth = pdf.internal.pageSize.width;
    const pageHeight = pdf.internal.pageSize.height;
    const contentWidth = pageWidth - 2 * margin;

    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(16);
    pdf.text("AGT Maker Content", pageWidth / 2, y, { align: "center" });
    y += 15;

    pdf.setTextColor(0, 0, 0);
    pdf.setDrawColor(colors.line);

    const addNewPageIfNeeded = (requiredSpace) => {
      if (y + requiredSpace > pageHeight - margin) {
        pdf.addPage();
        y = 20;
        return true;
      }
      return false;
    };

    let mermaidIndex = 0;
    let inMermaidBlock = false;
    let mermaidContent = '';

    for (const line of lines) {
      if (line.trim() === '```mermaid') {
        inMermaidBlock = true;
        mermaidContent = '';
        continue;
      }

      if (inMermaidBlock) {
        if (line.trim() === '```') {
          inMermaidBlock = false;
          addNewPageIfNeeded(150);
          
          // Add title
          pdf.setFont("helvetica", "bold");
          pdf.setFontSize(14);
          pdf.text(`Diagrama ${mermaidIndex + 1}`, margin, y);
          y += 10;

          try {
            const svg = await mermaid.render(`mermaid-pdf-${mermaidIndex}`, mermaidContent);
            const svgElement = new DOMParser().parseFromString(svg.svg, "image/svg+xml").documentElement;
            const svgData = new XMLSerializer().serializeToString(svgElement);
            const imgData = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
            
            pdf.addImage(imgData, 'SVG', margin, y, contentWidth, 100);
            y += 110;
          } catch (error) {
            console.error('Error rendering Mermaid diagram:', error);
            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(10);
            pdf.text("Error rendering diagram. Mermaid content:", margin, y);
            y += 10;
            const contentLines = pdf.splitTextToSize(mermaidContent, contentWidth);
            pdf.text(contentLines, margin, y);
            y += 5 * contentLines.length + 10;
          }

          // Add source
          pdf.setFont("helvetica", "italic");
          pdf.setFontSize(10);
          pdf.text("Fuente: AGT Maker by AGT, 2024", margin, y);
          y += 15;

          mermaidIndex++;
          continue;
        }
        mermaidContent += line + '\n';
        continue;
      }

      if (line.match(/^\d+\./)) {
        addNewPageIfNeeded(20);

        const parts = line.split(':');
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(12);
        if (parts.length > 1) {
          pdf.text(parts[0] + ':', margin, y);
          const boldWidth = pdf.getStringUnitWidth(parts[0] + ':') * 12 / pdf.internal.scaleFactor;
          
          pdf.setFont("helvetica", "normal");
          pdf.setFontSize(10);
          const remainingText = parts.slice(1).join(':').trim();
          const splitText = pdf.splitTextToSize(remainingText, contentWidth - boldWidth - 2);
          pdf.text(splitText, margin + boldWidth + 2, y);
          y += 5 * splitText.length;
        } else {
          pdf.text(line, margin, y);
          y += 7;
        }
        y += 5;
      } else if (line.trim().startsWith('-')) {
        addNewPageIfNeeded(7);
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(10);
        const bulletContent = '• ' + line.trim().substring(1).trim();
        const splitText = pdf.splitTextToSize(bulletContent, contentWidth - 5);
        pdf.text(splitText, margin + 5, y);
        y += 5 * splitText.length;
      } else {
        addNewPageIfNeeded(7);
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(10);
        const splitText = pdf.splitTextToSize(line, contentWidth);
        pdf.text(splitText, margin, y);
        y += 5 * splitText.length;
      }
      y += 2;
    }

    const pageCount = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      pdf.setPage(i);
      pdf.setFont("helvetica", "italic");
      pdf.setFontSize(8);
      pdf.setTextColor(128, 128, 128);
      pdf.text(`Page ${i} of ${pageCount}`, pageWidth - 20, pageHeight - 10);
    }

    pdf.save('agt-maker-content.pdf');
    handleDownloadClose();
  }, [displayedContent, colors.line, handleDownloadClose]);

  const handleDownloadDocx = useCallback(() => {
    const doc = new Document({
      styles: {
        paragraphStyles: [
          {
            id: 'Normal',
            name: 'Normal',
            basedOn: 'Normal',
            next: 'Normal',
            quickFormat: true,
            run: {
              size: 24,
              font: 'Times New Roman',
            },
            paragraph: {
              spacing: {
                line: 276,
              },
            },
          },
        ],
      },
      sections: [{
        properties: {},
        children: createDocxContent(),
      }],
    });

    Packer.toBlob(doc).then(blob => {
      saveAs(blob, 'agt-maker-content.docx');
      handleDownloadClose();
    });
  }, [handleDownloadClose]);

  const createDocxContent = useCallback(() => {
    const docxContent = [];
    const lines = displayedContent.split('\n');

    docxContent.push(new Paragraph({
      text: "AGT Maker Content",
      heading: HeadingLevel.TITLE,
      alignment: AlignmentType.CENTER,
      spacing: { before: 240, after: 400 },
      run: {
        size: 36,
        font: 'Calibri',
        bold: true,
      },
    }));

    lines.forEach((line) => {
      if (line.match(/^\d+\./)) {
        const parts = line.split(':');
        if (parts.length > 1) {
          docxContent.push(new Paragraph({
            children: [
              new TextRun({
                text: parts[0] + ':',
                bold: true,
                size: 28,
              }),
              new TextRun({
                text: parts.slice(1).join(':').trim(),
                size: 24,
              }),
            ],
            spacing: { before: 200, after: 100 },
          }));
        } else {
          docxContent.push(new Paragraph({
            text: line,
            bold: true,
            size: 28,
            spacing: { before: 200, after: 100 },
          }));
        }
      } else if (line.trim().startsWith('-')) {
        docxContent.push(new Paragraph({
          text: line.trim().substring(1).trim(),
          bullet: {
            level: 0,
          },
          indent: { left: 720, hanging: 360 },
          spacing: { before: 100, after: 100 },
        }));
      } else {
        docxContent.push(new Paragraph({ 
          text: line, 
          spacing: { before: 100, after: 100 },
          alignment: AlignmentType.JUSTIFIED,
        }));
      }
    });

    return docxContent;
  }, [displayedContent]);

  const toggleStyleMode = useCallback(() => {
    setStyleMode(prev => {
      switch (prev) {
        case 'grid':
          return 'lined';
        case 'lined':
          return 'blank';
        case 'blank':
        default:
          return 'grid';
      }
    });
  }, []);

  const getBackgroundImage = useCallback((mode) => {
    const lineColor = colors.line;
    switch (mode) {
      case 'grid':
        return `
          linear-gradient(${lineColor} 1px, transparent 1px),
          linear-gradient(90deg, ${lineColor} 1px, transparent 1px)
        `;
      case 'lined':
        return `
          linear-gradient(to bottom, transparent 23px, ${lineColor} 23px, ${lineColor} 24px, transparent 24px)
        `;
      case 'blank':
      default:
        return 'none';
    }
  }, [colors.line]);

  const handleZoomChange = useCallback((event, newValue) => {
    setZoomLevel(newValue);
  }, []);

  const handleMouseDown = useCallback((e) => {
    if (e.button === 0) {
      setIsPanning(true);
      e.target.style.cursor = 'grabbing';
    }
  }, []);

  const handleMouseUp = useCallback((e) => {
    setIsPanning(false);
    e.target.style.cursor = 'grab';
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (isPanning) {
      setPanOffset(prevOffset => ({
        x: prevOffset.x + e.movementX / zoomLevel,
        y: prevOffset.y + e.movementY / zoomLevel
      }));
    }
  }, [isPanning, zoomLevel]);

  const renderContent = useCallback(() => {
    const lines = visibleContent.split('\n');
    let mermaidIndex = 0;
    let inMermaidBlock = false;
    
    return lines.reduce((acc, line, index) => {
      if (line.trim() === '```mermaid') {
        inMermaidBlock = true;
        return acc;
      }
      
      if (inMermaidBlock) {
        if (line.trim() === '```') {
          inMermaidBlock = false;
          if (mermaidSvgs[mermaidIndex]) {
            acc.push(
              <div 
                key={`mermaid-${mermaidIndex}`}
                className="mermaid-diagram"
                dangerouslySetInnerHTML={{ __html: mermaidSvgs[mermaidIndex] }}
                style={{
                  marginBottom: '24px',
                  textAlign: 'center',
                  transform: `scale(${zoomLevel}) translate(${panOffset.x}px, ${panOffset.y}px)`,
                  transformOrigin: 'center',
                  transition: 'transform 0.1s ease',
                  cursor: 'grab'
                }}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseUp}
              />
            );
            mermaidIndex++;
          }
        }
        return acc;
      }
  
      const titleMatch = line.match(/^(\d+\.)\s+(.+)/);
      if (titleMatch) {
        const [, number, title] = titleMatch;
        acc.push(
          <h3 key={index} className="numbered-title">
            {number} {title}
          </h3>
        );
      } else if (line.trim().startsWith('-')) {
        acc.push(
          <p key={index} className="bullet-point">
            {line.trim().substring(1).trim()}
          </p>
        );
      } else if (line.trim().startsWith('#')) {
        const level = line.trim().split(' ')[0].length;
        const titleContent = line.trim().substring(level).trim();
        acc.push(React.createElement(`h${level}`, { 
          key: index, 
          style: { fontWeight: 'bold' }
        }, titleContent));
      } else if (line.trim() === '---') {
        acc.push(<hr key={index} />);
      } else if (line.trim().startsWith('\\[') && line.trim().endsWith('\\]')) {
        acc.push(<BlockMath key={index} math={line.trim().slice(2, -2)} />);
      } else if (line.includes('$')) {
        const parts = line.split(/(\$.*?\$)/);
        acc.push(
          <p key={index}>
            {parts.map((part, i) => 
              part.startsWith('$') && part.endsWith('$') 
                ? <InlineMath key={i} math={part.slice(1, -1)} />
                : part
            )}
          </p>
        );
      } else {
        acc.push(<p key={index}>{line}</p>);
      }
      return acc;
    }, []);
  }, [visibleContent, mermaidSvgs, zoomLevel, panOffset, handleMouseDown, handleMouseUp, handleMouseMove]);

  const WhiteboardContainer = styled(Box)(({ theme }) => ({
    position: 'fixed',
    top: '60px',
    right: '5px',
    width: '400px',
    height: '600px',
    backgroundColor: colors.cream,
    borderRadius: '30px',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    resize: 'both',
    maxWidth: '90vw',
    maxHeight: '90vh',
    border: styleMode !== 'blank' ? `1px solid ${colors.line}` : 'none',
    color: colors.text,
  }));

  const WhiteboardHeader = styled(Box)(({ theme }) => ({
    padding: '12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.line}`,
    backgroundColor: colors.cream,
    color: colors.text,
    borderTopLeftRadius: '30px',
    borderTopRightRadius: '30px',
  }));

  const WhiteboardTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '1.2rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: styleMode === 'blank' ? 'Arial, sans-serif' : '"Courier Prime", monospace',
  }));

  const ContentArea = styled(Box)(({ theme }) => ({
    flex: 1,
    overflow: 'auto',
    padding: '20px',
    backgroundColor: colors.cream,
    color: colors.text,
    backgroundImage: getBackgroundImage(styleMode),
    backgroundSize: styleMode === 'grid' ? '20px 20px' : '100% 24px',
    backgroundPosition: 'top left',
    backgroundAttachment: 'local',
    position: 'relative',
    '& p': {
      margin: '0 0 24px 0',
      lineHeight: '24px',
      fontSize: '16px',
      fontFamily: styleMode === 'blank' ? 'Arial, sans-serif' : '"Courier Prime", monospace',
    },
    '& h1, & h2, & h3, & h4, & h5, & h6': {
      marginTop: '24px',
      marginBottom: '16px',
      fontWeight: 'bold',
      lineHeight: '1.25',
      fontFamily: styleMode === 'blank' ? 'Arial, sans-serif' : '"Courier Prime", monospace',
      color: colors.text,
    },
    '& h1': { fontSize: '28px' },
    '& h2': { fontSize: '24px' },
    '& h3': { fontSize: '20px' },
    '& h4': { fontSize: '18px' },
    '& h5': { fontSize: '16px' },
    '& h6': { fontSize: '14px' },
    '& .numbered-title': {
      fontWeight: 'bold',
      marginBottom: '16px',
      fontSize: '20px',
    },
    '& .bullet-point': {
      position: 'relative',
      paddingLeft: '20px',
      marginBottom: '8px',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: '0',
        top: '8px',
        width: '6px',
        height: '6px',
        backgroundColor: colors.text,
        borderRadius: '50%',
      }
    },
    '& hr': {
      margin: '24px 0',
      border: 'none',
      borderTop: '1px solid',
      borderColor: colors.line,
    },
    '& .katex': {
      fontSize: '1.1em',
    },
    '& .mermaid-diagram': {
      backgroundColor: 'white',
      padding: '10px',
      borderRadius: '5px',
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    },
  }));

  const WhiteboardFooter = styled(Box)(({ theme }) => ({
    padding: '12px',
    borderTop: `1px solid ${colors.line}`,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: colors.cream,
    color: colors.text,
    borderBottomLeftRadius: '30px',
    borderBottomRightRadius: '30px',
  }));

  const ActionButton = styled(IconButton)(({ theme }) => ({
    color: colors.text,
    padding: '8px',
    '&:hover': {
      backgroundColor: `${colors.line}50`,
    },
  }));

  const ZoomControls = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: '10px',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    padding: '5px',
    borderRadius: '5px',
    zIndex: 1000,
  }));

  if (!isOpen || !hasContent) return null;

  return (
    <WhiteboardContainer>
      <WhiteboardHeader>
        <WhiteboardTitle>AGT Maker</WhiteboardTitle>
        <ActionButton onClick={onClose} size="small">
          <CloseIcon />
        </ActionButton>
      </WhiteboardHeader>
      <ContentArea ref={contentRef}>
        {!isContentReady ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
          </Box>
        ) : (
          renderContent()
        )}
        {showZoomControls && (
          <ZoomControls>
            <Tooltip title="Zoom out">
              <ActionButton onClick={() => setZoomLevel(prev => Math.max(prev - 0.1, 0.1))} size="small">
                <ZoomOutIcon />
              </ActionButton>
            </Tooltip>
            <Slider
              value={zoomLevel}
              onChange={handleZoomChange}
              min={0.1}
              max={5}
              step={0.1}
              aria-labelledby="zoom-slider"
              sx={{ width: 100 }}
            />
            <Tooltip title="Zoom in">
              <ActionButton onClick={() => setZoomLevel(prev => Math.min(prev + 0.1, 5))} size="small">
                <ZoomInIcon />
              </ActionButton>
            </Tooltip>
          </ZoomControls>
        )}
      </ContentArea>
      {isContentReady && (
        <WhiteboardFooter>
          <Tooltip title="Copy content">
            <ActionButton onClick={handleCopy} size="small">
              <ContentCopyIcon />
            </ActionButton>
          </Tooltip>
          <Tooltip title="Download content">
            <ActionButton onClick={handleDownloadClick} size="small">
              <DownloadIcon />
            </ActionButton>
          </Tooltip>
          <Tooltip title="Toggle style mode">
            <ActionButton onClick={toggleStyleMode} size="small">
              <AutoStoriesIcon />
            </ActionButton>
          </Tooltip>
        </WhiteboardFooter>
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleDownloadClose}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => { handleDownloadTxt(); handleDownloadClose(); }}>Download as TXT</MenuItem>
        <MenuItem onClick={() => { handleDownloadPdf(); handleDownloadClose(); }}>Download as PDF</MenuItem>
        <MenuItem onClick={() => { handleDownloadDocx(); handleDownloadClose(); }}>Download as Word</MenuItem>
      </Popover>
    </WhiteboardContainer>
  );
};

export default React.memo(AGTMaker);