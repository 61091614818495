import React from 'react';
import { Box, Typography, Avatar, Button } from '@mui/material';
import { styled } from '@mui/system';

const ProfileContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(3),
  backgroundColor: 'rgba(128, 128, 128, 0.3)',
  borderRadius: '20px',
  backdropFilter: 'blur(10px)',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  color: 'white',
  maxWidth: '500px',
  width: '90%',
  margin: '0 auto',
  [theme.breakpoints.down('sm')]: {
    width: '85%',
    maxWidth: '350px',
  },
}));

const ProfileImage = styled(Avatar)(({ theme }) => ({
  width: 120,
  height: 120,
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    width: 80,
    height: 80,
  },
}));

const NameDisplay = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem',
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  borderRadius: '20px',
  padding: theme.spacing(1, 3),
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
}));

const ProfileDashboard = ({ user, onLogout }) => {
  return (
    <ProfileContainer>
      <ProfileImage src={user?.photoURL} alt={user?.displayName} />
      <NameDisplay>{user?.displayName || 'Usuario'}</NameDisplay>
      
      <CustomButton onClick={onLogout} sx={{ marginTop: 2 }}>
        Cerrar sesión
      </CustomButton>
    </ProfileContainer>
  );
};

export default ProfileDashboard;