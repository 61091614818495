import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  GoogleAuthProvider, 
  signInWithPopup,
  OAuthProvider,
  setPersistence,
  browserLocalPersistence
} from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import MicrosoftIcon from '@mui/icons-material/Microsoft';

// Configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCl_QY3Y0RYRNuMc541mwhBlMW2VbLiqhQ",
  authDomain: "nest-cd957.firebaseapp.com",
  projectId: "nest-cd957",
  storageBucket: "nest-cd957.appspot.com",
  messagingSenderId: "703548963271",
  appId: "1:703548963271:web:236d5d9dd29a0d8e9141e2",
  measurementId: "G-S7XKCXJFLT"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

// Configurar persistencia de sesión
setPersistence(auth, browserLocalPersistence);

const AuthContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(3),
  width: '100%',
  maxWidth: '400px',
  margin: '0 auto',
}));

const RoundedTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '100px',
    color: 'white',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.2)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.4)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.6)',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.8)',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'rgba(255, 255, 255, 0.8)',
  },
  marginBottom: theme.spacing(2),
  width: '100%',
}));

const AuthButton = styled(Button)(({ theme }) => ({
  borderRadius: '100px',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: 'rgba(0, 0, 0, 0.45)',
  color: 'white',
  height: '48px',
  width: '100%',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.55)',
  },
}));

const SocialButton = styled(Button)(({ theme }) => ({
  borderRadius: '100px',
  marginTop: theme.spacing(1),
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: 'white',
  height: '48px',
  width: '100%',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
}));

const Auth = ({ onLogin, mode, onToggleMode }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        handleSuccessfulLogin(user);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSuccessfulLogin = async (user) => {
    try {
      const token = await user.getIdToken();
      localStorage.setItem('token', token);
      onLogin(user);
    } catch (error) {
      console.error('Error al obtener el token:', error);
      setMessage('Error al iniciar sesión. Por favor, intenta de nuevo.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    try {
      let result;
      if (mode === 'login') {
        result = await signInWithEmailAndPassword(auth, email, password);
        setMessage('Inicio de sesión exitoso');
      } else {
        result = await createUserWithEmailAndPassword(auth, email, password);
        setMessage('Usuario registrado con éxito');
      }
      await handleSuccessfulLogin(result.user);
    } catch (error) {
      console.error('Error:', error);
      setMessage(getErrorMessage(error.code));
    }
  };

  const handleSocialLogin = async (provider) => {
    try {
      let authProvider;
      switch (provider) {
        case 'google':
          authProvider = new GoogleAuthProvider();
          authProvider.addScope('https://www.googleapis.com/auth/userinfo.email');
          authProvider.addScope('https://www.googleapis.com/auth/userinfo.profile');
          authProvider.setCustomParameters({
            prompt: 'select_account'
          });
          break;
        case 'microsoft':
          authProvider = new OAuthProvider('microsoft.com');
          authProvider.setCustomParameters({
            prompt: 'select_account'
          });
          break;
        case 'apple':
          authProvider = new OAuthProvider('apple.com');
          break;
        default:
          throw new Error('Proveedor no soportado');
      }
      const result = await signInWithPopup(auth, authProvider);
      await handleSuccessfulLogin(result.user);
      setMessage(`Inicio de sesión con ${provider} exitoso`);
    } catch (error) {
      console.error('Error de inicio de sesión social:', error);
      setMessage(getErrorMessage(error.code));
    }
  };

  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/user-not-found':
        return 'No se encontró ninguna cuenta con este correo electrónico.';
      case 'auth/wrong-password':
        return 'Contraseña incorrecta.';
      case 'auth/email-already-in-use':
        return 'Ya existe una cuenta con este correo electrónico.';
      case 'auth/weak-password':
        return 'La contraseña es demasiado débil.';
      case 'auth/invalid-email':
        return 'El formato del correo electrónico no es válido.';
      case 'auth/account-exists-with-different-credential':
        return 'Ya existe una cuenta con este correo electrónico usando un método de inicio de sesión diferente.';
      case 'auth/popup-closed-by-user':
        return 'La ventana de inicio de sesión fue cerrada antes de completar la operación.';
      default:
        return 'Ocurrió un error durante la autenticación. Por favor, intenta de nuevo.';
    }
  };

  return (
    <AuthContainer>
      <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2, color: 'white' }}>
        {mode === 'login' ? 'Welcome back' : 'Create an account'}
      </Typography>
      
      <SocialButton
        startIcon={<GoogleIcon />}
        onClick={() => handleSocialLogin('google')}>
        Continue with Google
      </SocialButton>
      <SocialButton
        startIcon={<MicrosoftIcon />}
        onClick={() => handleSocialLogin('microsoft')}
      >
        Continue with Microsoft
      </SocialButton>
      <SocialButton
        startIcon={<AppleIcon />}
        onClick={() => handleSocialLogin('apple')}
      >
        Continue with Apple
      </SocialButton>

      <Divider sx={{ my: 2, width: '100%', bgcolor: 'rgba(255, 255, 255, 0.2)' }}>
        <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
          Or
        </Typography>
      </Divider>

      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: '100%' }}>
        <RoundedTextField
          variant="outlined"
          required
          fullWidth
          id="email"
          label="Email address"
          name="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <RoundedTextField
          variant="outlined"
          required
          fullWidth
          id="password"
          label="Password"
          name="password"
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <AuthButton type="submit" fullWidth variant="contained">
          {mode === 'login' ? 'Continue' : 'Register'}
        </AuthButton>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body2" sx={{ color: 'white' }}>
            {mode === 'login' ? "Don't have an account?" : 'Already have an account?'}{' '}
            <Typography
              variant="body2"
              component="span"
              onClick={onToggleMode}
              sx={{ color: 'rgba(255, 255, 255, 0.7)', cursor: 'pointer' }}
            >
              {mode === 'login' ? 'Sign Up' : 'Log In'}
            </Typography>
          </Typography>
        </Box>
      </Box>

      {message && (
        <Typography color="primary" sx={{ mt: 2 }}>
          {message}
        </Typography>
      )}
    </AuthContainer>
  );
};

export default Auth;