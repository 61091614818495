import React, { useState, useCallback, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  IconButton, 
  Tooltip, 
  Modal, 
  TextField, 
  Select, 
  MenuItem, 
  Button 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { sendFeedback } from './AGTService';
import InlineImageSearch from './InlineImageSearch';

const MessageContainer = styled(Box)(({ theme, isUser }) => ({
  display: 'flex',
  justifyContent: isUser ? 'flex-end' : 'flex-start',
  marginBottom: '20px',
  position: 'relative',
  width: '100%',
}));

const MessageContent = styled(Box)(({ theme, isUser }) => ({
  flexGrow: 1,
  maxWidth: isUser ? 'fit-content' : 'calc(100% - 60px)',
  padding: isUser ? '8px 12px' : '12px 16px',
  borderRadius: '18px',
  backgroundColor: isUser ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 0)',
  color: 'white',
  position: 'relative',
}));

const StyledReactMarkdown = styled(ReactMarkdown)(({ theme }) => ({
  '& p': {
    margin: '0.5em 0',
  },
  '& ul, & ol': {
    marginLeft: '1.5em',
  },
  '& li': {
    marginBottom: '0.5em',
  },
  '& strong': {
    color: '#FFFFFF',
  },
  '& em': {
    fontStyle: 'italic',
    color: '#FFFFFF',
  },
  '& h1, & h2, & h3, & h4, & h5, & h6': {
    margin: '0.5em 0',
    color: '#FFFFFF',
  },
  '& blockquote': {
    borderLeft: '3px solid #4CAF50',
    paddingLeft: '10px',
    margin: '0.5em 0',
    fontStyle: 'italic',
  },
  '& code': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    padding: '2px 4px',
    borderRadius: '4px',
  },
  '& .enumeration-keyword': {
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
}));

const AssistantIconContainer = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '10px',
}));

const AssistantIcon = styled('img')({
  width: '24px',
  height: '24px',
  objectFit: 'contain',
});

const MessageWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
});

const FeedbackContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: '6px',
  bottom: '6px',
  display: 'flex',
  gap: '1px',
}));

const FeedbackButton = styled(IconButton)(({ theme, $active }) => ({
  padding: '2px',
  color: $active ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.7)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const ModalContent = styled(Box)(({ theme }) => ({
  width: '300px',
  padding: '15px',
  borderRadius: '20px',
  overflow: 'hidden',
  backgroundColor: 'rgba(128, 128, 128, 0.3)',
  backdropFilter: 'blur(12px)',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: '10px',
  width: '100%',
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.7)',
    },
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  marginTop: '10px',
  width: '100%',
  color: 'white',
  '& .MuiSelect-icon': {
    color: 'white',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(255, 255, 255, 0.3)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(255, 255, 255, 0.5)',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(255, 255, 255, 0.7)',
  },
}));

const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  marginTop: '15px',
  gap: '10px',
});

const SubmitButton = styled(Button)({
  borderRadius: '30px',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
});

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  color: 'white',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  '& .MuiSvgIcon-root': {
    color: 'white',
  },
  textTransform: 'none',
  padding: '6px 12px',
  margin: '10px 0',
}));

const OnDemandImageSearch = ({ initialQuery, onImageSelect, showAutomatically }) => {
  const [isSearchVisible, setIsSearchVisible] = useState(showAutomatically);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleSearchClick = useCallback(() => {
    setIsSearchVisible(true);
  }, []);

  const handleImageSelect = useCallback((image) => {
    setSelectedImage(image);
    onImageSelect(image);
    setIsSearchVisible(false);
  }, [onImageSelect]);

  if (selectedImage) {
    return (
      <div>
        <img src={selectedImage.link} alt={selectedImage.title} style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }} />
        <Typography variant="caption">
          <a href={selectedImage.image.contextLink} target="_blank" rel="noopener noreferrer">Ver en la web</a>
        </Typography>
      </div>
    );
  }

  if (isSearchVisible) {
    return <InlineImageSearch onImageSelect={handleImageSelect} initialQuery={initialQuery} />;
  }

  return (
    <StyledButton
      startIcon={<SearchIcon />}
      onClick={handleSearchClick}
    >
      Abrir AGT Search
    </StyledButton>
  );
};

const MagicalMessage = ({ 
  content, 
  isUser, 
  isLastMessage, 
  onFeedback, 
  userId, 
  messageId,
  hasAGTMaker,
  onOpenAGTMaker,
  isLoading,
  onUpdateMessage,
  lastImageRequestId
}) => {
  const [processedContent, setProcessedContent] = useState([]);
  const [copied, setCopied] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [openLikeModal, setOpenLikeModal] = useState(false);
  const [openDislikeModal, setOpenDislikeModal] = useState(false);
  const [feedbackDetails, setFeedbackDetails] = useState('');
  const [dislikeReason, setDislikeReason] = useState('Other');

  useEffect(() => {
    if (!isUser) {
      const parts = content.split(/(<agt>.*?<\/agt>|<AGTSearch:image:.*?>)/gs);
      const processedParts = parts.map((part, index) => {
        if (part.startsWith('<agt>') && part.endsWith('</agt>')) {
          return (
            <StyledButton
              key={index}
              startIcon={<EditIcon />}
              onClick={onOpenAGTMaker}
            >
              Abrir AGT Maker
            </StyledButton>
          );
        } else if (part.startsWith('<AGTSearch:image:')) {
          const imageQuery = part.match(/<AGTSearch:image:(.*?)>/)[1];
          return (
            <OnDemandImageSearch
              key={index}
              initialQuery={imageQuery}
              onImageSelect={(selectedImage) => handleImageSelect(selectedImage, index)}
              showAutomatically={messageId === lastImageRequestId}
            />
          );
        } else {
          return (
            <StyledReactMarkdown key={index} rehypePlugins={[rehypeRaw]}>
              {part}
            </StyledReactMarkdown>
          );
        }
      });
      setProcessedContent(processedParts);
    } else {
      setProcessedContent([content]);
    }
  }, [content, isUser, messageId, lastImageRequestId, onOpenAGTMaker]);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(content).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  }, [content]);

  const handleFeedback = useCallback((value) => {
    setFeedback(value);
    if (value === 'like') {
      setOpenLikeModal(true);
    } else if (value === 'dislike') {
      setOpenDislikeModal(true);
    }
  }, []);

  const handleSubmitFeedback = useCallback(async () => {
    try {
      if (!userId || !messageId || !feedback) {
        throw new Error('Missing required fields for feedback');
      }

      await sendFeedback(
        userId,
        messageId,
        feedback,
        feedbackDetails,
        feedback === 'dislike' ? dislikeReason : null
      );

      if (onFeedback) {
        onFeedback({
          type: feedback,
          details: feedbackDetails,
          reason: feedback === 'dislike' ? dislikeReason : null,
        });
      }

      setOpenLikeModal(false);
      setOpenDislikeModal(false);
      setFeedbackDetails('');
      setDislikeReason('Other');

      alert('Feedback enviado con éxito');
    } catch (error) {
      console.error('Error al enviar feedback:', error);
      alert('Hubo un error al enviar el feedback. Por favor, intenta de nuevo.');
    }
  }, [userId, messageId, feedback, feedbackDetails, dislikeReason, onFeedback]);

  const handleImageSelect = useCallback((selectedImage, index) => {
    const newContent = [...processedContent];
    newContent[index] = (
      <React.Fragment key={index}>
        <img src={selectedImage.link} alt={selectedImage.title} style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }} />
        <br />
        <a href={selectedImage.image.contextLink} target="_blank" rel="noopener noreferrer">Ver en la web</a>
      </React.Fragment>
    );
    setProcessedContent(newContent);
    // No actualizamos el mensaje aquí para mantener el botón visible hasta que se seleccione una imagen
  }, [processedContent]);

  return (
    <MessageContainer isUser={isUser}>
      {isUser ? (
        <MessageContent isUser={isUser}>
          <Typography style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
            {processedContent}
          </Typography>
        </MessageContent>
      ) : (
        <MessageWrapper>
          <AssistantIconContainer>
            <AssistantIcon src="/images/Icon/AGT.svg" alt="Assistant" />
          </AssistantIconContainer>
          <MessageContent isUser={isUser}>
            {processedContent}
            <FeedbackContainer>
              <Tooltip title="Me gusta" placement="top">
                <FeedbackButton 
                  onClick={() => handleFeedback('like')} 
                  $active={feedback === 'like'}
                  size="small"
                >
                  <ThumbUpIcon style={{ fontSize: '14px' }} />
                </FeedbackButton>
              </Tooltip>
              <Tooltip title="No me gusta" placement="top">
                <FeedbackButton 
                  onClick={() => handleFeedback('dislike')} 
                  $active={feedback === 'dislike'}
                  size="small"
                >
                  <ThumbDownIcon style={{ fontSize: '14px' }} />
                </FeedbackButton>
              </Tooltip>
              <Tooltip title={copied ? "Copiado" : "Copiar mensaje"} placement="top">
                <FeedbackButton 
                  onClick={handleCopy}
                  size="small"
                >
                  <ContentCopyIcon style={{ fontSize: '14px' }} />
                </FeedbackButton>
              </Tooltip>
              {hasAGTMaker && (
                <Tooltip title="Abrir AGT Maker" placement="top">
                  <FeedbackButton 
                    onClick={onOpenAGTMaker}
                    size="small"
                  >
                    <EditIcon style={{ fontSize: '14px' }} />
                  </FeedbackButton>
                </Tooltip>
              )}
            </FeedbackContainer>
          </MessageContent>
        </MessageWrapper>
      )}

      <Modal open={openLikeModal} onClose={() => setOpenLikeModal(false)}>
        <ModalContent>
          <Typography variant="h6" style={{ color: 'white', marginBottom: '10px' }}>Feedback</Typography>
          <StyledTextField
            multiline
            rows={4}
            variant="outlined"
            placeholder="What was satisfying about this response? (optional)"
            value={feedbackDetails}
            onChange={(e) => setFeedbackDetails(e.target.value)}
          />
          <ButtonContainer>
            <Button onClick={() => setOpenLikeModal(false)} style={{ color: 'white' }}>Cancel</Button>
            <SubmitButton onClick={handleSubmitFeedback} variant="contained">Submit</SubmitButton>
          </ButtonContainer>
        </ModalContent>
      </Modal>

      <Modal open={openDislikeModal} onClose={() => setOpenDislikeModal(false)}>
        <ModalContent>
          <Typography variant="h6" style={{ color: 'white', marginBottom: '10px' }}>Feedback</Typography>
          <StyledSelect
            value={dislikeReason}
            onChange={(e) => setDislikeReason(e.target.value)}
          >
            <MenuItem value="UI bug">UI bug</MenuItem>
            <MenuItem value="Harmful content">Harmful content</MenuItem>
            <MenuItem value="Overactive refusal">Overactive refusal</MenuItem>
            <MenuItem value="Did not fully follow my request">Did not fully follow my request</MenuItem>
            <MenuItem value="Not factually correct">Not factually correct</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </StyledSelect>
          <StyledTextField
            multiline
            rows={4}
            variant="outlined"
            placeholder="What was unsatisfying about this response? (optional)"
            value={feedbackDetails}
            onChange={(e) => setFeedbackDetails(e.target.value)}
          />
          <ButtonContainer>
            <Button onClick={() => setOpenDislikeModal(false)} style={{ color: 'white' }}>Cancel</Button>
            <SubmitButton onClick={handleSubmitFeedback} variant="contained">Submit</SubmitButton>
          </ButtonContainer>
        </ModalContent>
      </Modal>
    </MessageContainer>
  );
};

export default React.memo(MagicalMessage);