import React, { useMemo } from 'react';
import { Box, Card as MuiCard, Typography } from '@mui/material';
import { MessageCircle, ShieldCheck, Edit, Globe } from 'lucide-react';
import { styled } from '@mui/system';

// Estilos personalizados para las tarjetas (Card)
const CustomCard = styled(MuiCard)(({ theme }) => ({
  width: '100%',
  cursor: 'pointer',
  borderRadius: '30px',
  marginBottom: '10px',
  transition: 'box-shadow 0.3s, border 0.3s',
  backgroundColor: 'rgba(128, 128, 128, 0)',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backdropFilter: 'blur(10px)', // Aplicamos el efecto de desenfoque por defecto

  '&:hover': {
    boxShadow: '0 6px 8px rgba(0, 0, 0, 0.2)',
    border: '1px solid rgba(255, 255, 255, 0.4)',
  },

  // Media query para dispositivos móviles
  [theme.breakpoints.down('sm')]: {
    backdropFilter: 'none', // Eliminamos el efecto de desenfoque en dispositivos móviles
  },
}));

// Estilos personalizados para el contenido de las tarjetas (CardContent)
const CustomCardContent = styled(Box)({
  width: '100%',
  height: '100%',
  padding: '15px',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: 'white',
  gap: '10px',
});

const promptCards = [
  { Icon: MessageCircle, text: "Responde de manera más humana", color: "#3B82F6" },
  { Icon: ShieldCheck, text: "Haz que mi contenido esté libre de plagio", color: "#EF4444" },
  { Icon: Edit, text: "Ayúdame a escribir un mensaje claro y efectivo", color: "#F59E0B" },
  { Icon: Globe, text: "Traduce este texto al inglés", color: "#10B981" }
];

const PromptCard = React.memo(({ Icon, text, color, onClick }) => (
  <CustomCard onClick={onClick}>
    <CustomCardContent>
      <Icon size={24} color={color} />
      <Typography variant="body2">
        {text}
      </Typography>
    </CustomCardContent>
  </CustomCard>
));

const PromptCards = React.memo(({ onSelectPrompt }) => {
  const cards = useMemo(() => promptCards.map((card, index) => (
    <PromptCard
      key={index}
      Icon={card.Icon}
      text={card.text}
      color={card.color}
      onClick={() => onSelectPrompt(card.text)}
    />
  )), [onSelectPrompt]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
      {cards}
    </Box>
  );
});

export default PromptCards;