import React, { useState, useEffect, useCallback, useRef } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  InputAdornment, 
  Button, 
  IconButton, 
  CircularProgress, 
  Dialog, 
  DialogActions, 
  DialogContent,
  DialogTitle,
  Menu,
  Checkbox,
  Snackbar,
  Alert,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { styled } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BrushIcon from '@mui/icons-material/Brush';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Trash2 } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { getSummaries, searchSummaries, editSummary, deleteNote } from './AGTService';
import debounce from 'lodash/debounce';
import NoteViewDialog from './NoteViewDialog';

const SearchBarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  maxWidth: '800px',
  margin: '0 auto',
  marginBottom: theme.spacing(4),
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(20px)',
  borderRadius: '50px',
  padding: theme.spacing(1.5),
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    padding: theme.spacing(1),
  },
}));

const StyledInput = styled(TextField)(({ theme }) => ({
  flexGrow: 1,
  '& .MuiInputBase-root': {
    color: 'white',
    backgroundColor: 'transparent',
    borderRadius: '50px',
    paddingLeft: theme.spacing(2),
    '&:before, &:after': {
      borderBottom: 'none',
    },
    '&:hover:before': {
      borderBottom: 'none !important',
    },
  },
  '& .MuiInputBase-input': {
    padding: '10px 0',
    color: 'white',
  },
  '& .MuiInput-underline:before': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline:after': {
    borderBottom: 'none',
  },
}));

const NotaCardContainer = styled(Box)(({ theme, color, ispersonalized }) => ({
  width: '200px',
  height: '250px',
  borderRadius: '12px',
  overflow: 'visible',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  cursor: 'pointer',
  transition: 'all 0.3s ease-in-out',
  backdropFilter: 'blur(12px)',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    transform: 'scale(1.05)',
    zIndex: 10,
    boxShadow: '0 8px 12px rgba(0, 0, 0, 0.2)',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: ispersonalized === 'true' ? `${color}80` : 'rgba(128, 128, 128, 0.3)',
    zIndex: 1,
    borderRadius: '12px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '280px',
    height: '350px',
  },
}));

const CardContent = styled(Box)({
  width: '100%',
  height: '100%',
  padding: '15px',
  borderRadius: '12px',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  zIndex: 2,
});

const NavigationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '120px',
  height: '60px',
  marginTop: '30px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(20px)',
  borderRadius: '30px',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  padding: '10px',
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    justifyContent: 'space-between',
    padding: '10px 20px',
  },
}));

const NavigationButton = styled(IconButton)({
  color: 'white',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
});

const NotasContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: '24px',
  color: 'white',
  height: '100%',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': 'none',
  'scrollbarWidth': 'none',
}));

const DeleteContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: '-360px',
  bottom: '-100px',
  transform: 'translateY(50%)',
  backgroundColor: 'rgba(255, 255, 255, 0)',
  backdropFilter: 'blur(20px)',
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.down('sm')]: {
    position: 'static',
    transform: 'none',
  },
  [theme.breakpoints.up('md')]: {
    bottom: '25px', // Ajusta este valor para mover el icono más arriba en escritorio
  },
}));

const ColorOption = styled(Box)(({ color, selected }) => ({
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  backgroundColor: color,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    opacity: 0.8,
  },
  border: selected ? '2px solid white' : 'none',
}));

const SelectionCheckbox = styled(Checkbox)({
  position: 'absolute',
  bottom: 5,
  left: '50%',
  transform: 'translateX(-50%)',
  color: 'white',
  '&.Mui-checked': {
    color: 'white',
  },
});

const SelectionButtons = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
  gap: '10px',
});

const NotasGrid = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '24px',
  width: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '12px',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const PaletaDeColores = ({ anchorEl, open, onClose, onColorChange, selectedColor }) => {
  const colors = [
    { name: 'Red', hex: '#FF453A' },
    { name: 'Orange', hex: '#FF9F0A' },
    { name: 'Yellow', hex: '#FFD60A' },
    { name: 'Green', hex: '#32D74B' },
    { name: 'Mint', hex: '#66D4CF' },
    { name: 'Teal', hex: '#6AC4DC' },
    { name: 'Cyan', hex: '#5AC8F5' },
    { name: 'Blue', hex: '#0A84FF' },
    { name: 'Indigo', hex: '#5E5CE6' },
    { name: 'Purple', hex: '#BF5AF2' },
    { name: 'Pink', hex: '#FF375F' },
    { name: 'Brown', hex: '#AC8E68' },
  ];

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          maxHeight: 400,
          width: '300px',
          backgroundColor: 'rgba(128, 128, 128, 0.3)',
          backdropFilter: 'blur(12px)',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
          padding: '15px',
        },
      }}
    >
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 2 }}>
        {colors.map((color) => (
          <Box key={color.name} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <ColorOption
              color={color.hex}
              onClick={() => onColorChange(color.hex)}
              selected={selectedColor === color.hex}
            >
              {selectedColor === color.hex && (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    border: '2px solid white',
                    borderRadius: '50%',
                  }}
                />
              )}
            </ColorOption>
            <Typography variant="caption" sx={{ color: 'white', fontSize: '0.7rem', marginTop: '4px' }}>
              {color.name}
            </Typography>
          </Box>
        ))}
      </Box>
      <Button
        onClick={() => onColorChange(null)}
        fullWidth
        sx={{
          color: 'white',
          marginTop: 2,
          textTransform: 'none',
          borderRadius: '100px',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
          },
        }}
      >
        Reset
      </Button>
    </Menu>
  );
};

const TrashPanel = ({ open, anchorEl, onClose, onSelectMode, onDeleteAll }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: 'rgba(128, 128, 128, 0.3)',
          backdropFilter: 'blur(12px)',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
          padding: '15px',
          width: '200px',
        },
      }}
    >
      <Button
        fullWidth
        onClick={onSelectMode}
        sx={{
          color: 'white',
          marginBottom: 1,
          textTransform: 'none',
          borderRadius: '100px',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
          },
        }}
      >
        Seleccionar notas
      </Button>
      <Button
        fullWidth
        onClick={onDeleteAll}
        sx={{
          color: 'white',
          backgroundColor: '#FF453A',
          textTransform: 'none',
          borderRadius: '100px',
          '&:hover': {
            backgroundColor: '#FF6B6B',
          },
        }}
      >
        Eliminar todas
      </Button>
    </Menu>
  );
};

const ConfirmDeleteAll = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: 'rgba(128, 128, 128, 0.3)',
          backdropFilter: 'blur(12px)',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
          padding: '20px',
          color: 'white',
        },
      }}
    >
      <Typography variant="h6" gutterBottom>
        ¿Estás seguro?
      </Typography>
      <Typography>
        Esto eliminará todas las notas creadas. Esta acción no se puede deshacer.
      </Typography>
      <DialogActions>
        <Button onClick={onClose} sx={{ color: 'white' }}>
          Cancelar
        </Button>
        <Button 
          onClick={onConfirm} 
          sx={{ 
            backgroundColor: '#FF453A', 
            color: 'white',
            '&:hover': {
              backgroundColor: '#FF6B6B',
            },
          }}
        >
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const NotaCard = ({ summary, onClick, color, isPersonalized, onColorPickerOpen, selectMode, isSelected, onSelect }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Fecha inválida';
    }

    const opciones = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    };
    const fechaFormateada = date.toLocaleDateString('es-ES', opciones);

    let [dia, mes, año] = fechaFormateada.split(' de ');

    return `${mes.charAt(0).toUpperCase() + mes.slice(1)} ${dia}, ${año}`;
  };

  return (
    <NotaCardContainer 
      onClick={selectMode ? () => onSelect(summary.timestamp) : onClick}
      color={color}
      ispersonalized={isPersonalized.toString()}
    >
      <CardContent>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onColorPickerOpen(e, summary.timestamp);
          }}
          sx={{
            position: 'absolute',
            top: 5,
            right: 5,
            color: 'white',
            border: '1px solid white',
            borderRadius: '50%',
            padding: '4px',
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
            },
            zIndex: 3,
          }}
        >
          <BrushIcon sx={{ color: 'white' }} />
        </IconButton>
        <Typography 
          gutterBottom 
          variant="h6" 
          component="div" 
          fontSize="1rem"
          style={{ 
            color: 'white', 
            textAlign: 'center',
            marginBottom: '10px'
          }}
        >
          {summary.title || 'Sin título'}
        </Typography>
        <Typography 
          variant="body2"
          style={{ 
            color: 'white',
            textAlign: 'center',
            position: 'absolute',
            bottom: 50,
            left: 0,
            right: 0
          }}
        >
          {formatDate(summary.createdAt)}
        </Typography>
      </CardContent>
      {selectMode && (
        <SelectionCheckbox
          checked={isSelected}
          onChange={() => onSelect(summary.timestamp)}
          onClick={(e) => e.stopPropagation()}
        />
      )}
    </NotaCardContainer>
  );
};

const Notas = ({ user }) => {
  const [summaries, setSummaries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedNotes, setSelectedNotes] = useState([]);
  const [colorPickerAnchorEl, setColorPickerAnchorEl] = useState(null);
  const [noteColors, setNoteColors] = useState({});
  const [activeNoteId, setActiveNoteId] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectMode, setSelectMode] = useState(false);
  const [trashAnchorEl, setTrashAnchorEl] = useState(null);
  const [confirmDeleteAllOpen, setConfirmDeleteAllOpen] = useState(false);
  const [expandedNote, setExpandedNote] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [direction, setDirection] = useState(0);
  const [isSearching, setIsSearching] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const itemsPerPage = isMobile ? 1 : 4;

  const debouncedFetchSummaries = useRef(
    debounce((query) => {
      fetchSummaries(query);
    }, 300)
  ).current;

  const fetchSummaries = useCallback(async (query = '') => {
    setIsSearching(true);
    try {
      let data;
      if (query) {
        data = await searchSummaries(user.uid, query);
      } else {
        data = await getSummaries(user.uid);
      }
      
      const summariesArray = Array.isArray(data.summaries) ? data.summaries : [];
      
      setSummaries(summariesArray);
      
      const colors = {};
      summariesArray.forEach(summary => {
        if (summary.color) {
          colors[summary.timestamp] = summary.color;
        }
      });
      setNoteColors(colors);
      
      setPageIndex(0);
    } catch (err) {
      console.error('Error fetching summaries:', err);
      setError('Error al cargar los resúmenes. Por favor, intenta de nuevo más tarde.');
      setSummaries([]);
    } finally {
      setIsSearching(false);
      setLoading(false);
    }
  }, [user.uid]);

  useEffect(() => {
    fetchSummaries(searchQuery);
  }, [fetchSummaries, searchQuery]);

  useEffect(() => {
    return () => {
      debouncedFetchSummaries.cancel();
    };
  }, [debouncedFetchSummaries]);

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setIsSearching(true);
    debouncedFetchSummaries(query);
  };

  const handleNext = () => {
    if (pageIndex < Math.ceil(summaries.length / itemsPerPage) - 1) {
      setDirection(1);
      setPageIndex(pageIndex + 1);
    }
  };

  const handleBack = () => {
    if (pageIndex > 0) {
      setDirection(-1);
      setPageIndex(pageIndex - 1);
    }
  };

  const handleColorChange = async (color) => {
    if (activeNoteId) {
      try {
        const response = await editSummary(user.uid, activeNoteId, null, color);
        if (response.success) {
          setNoteColors(prev => ({
            ...prev,
            [activeNoteId]: color
          }));
          setSelectedColor(color);
          setSummaries(prevSummaries => 
            prevSummaries.map(summary => 
              summary.timestamp === activeNoteId 
                ? { ...summary, color: color }
                : summary
            )
          );
          setSnackbarMessage('Color de la nota actualizado');
          setSnackbarOpen(true);
        } else {
          throw new Error(response.error || 'Failed to update note color');
        }
      } catch (error) {
        console.error('Error updating note color:', error);
        setSnackbarMessage('Error al actualizar el color de la nota. Por favor, intenta de nuevo.');
        setSnackbarOpen(true);
      }
    }
    setColorPickerAnchorEl(null);
  };

  const handleColorPickerOpen = (event, noteId) => {
    event.stopPropagation();
    setColorPickerAnchorEl(event.currentTarget);
    setActiveNoteId(noteId);
    setSelectedColor(noteColors[noteId] || null);
  };

  const handleTrashClick = (event) => {
    setTrashAnchorEl(event.currentTarget);
  };

  const handleTrashClose = () => {
    setTrashAnchorEl(null);
  };

  const handleSelectMode = () => {
    setSelectMode(!selectMode);
    if (selectMode) {
      setSelectedNotes([]);
    }
    handleTrashClose();
  };

  const handleDeleteAllClick = () => {
    setConfirmDeleteAllOpen(true);
    handleTrashClose();
  };

  const handleConfirmDeleteAll = async () => {
    try {
      for (const summary of summaries) {
        await deleteNote(user.uid, summary.timestamp);
      }
      setSummaries([]);
      setSelectedNotes([]);
      setConfirmDeleteAllOpen(false);
      setSnackbarMessage('Todas las notas han sido eliminadas');
      setSnackbarOpen(true);
      fetchSummaries();
    } catch (error) {
      console.error('Error deleting all notes:', error);
      setSnackbarMessage('Error al eliminar todas las notas. Por favor, intenta de nuevo.');
      setSnackbarOpen(true);
    }
  };

  const handleNoteSelect = (noteId) => {
    setSelectedNotes(prev => 
      prev.includes(noteId) 
        ? prev.filter(id => id !== noteId)
        : [...prev, noteId]
    );
  };

  const handleDeleteSelected = async () => {
    try {
      for (const noteId of selectedNotes) {
        await deleteNote(user.uid, noteId);
      }
      setSelectedNotes([]);
      setSelectMode(false);
      setSnackbarMessage('Notas seleccionadas eliminadas');
      setSnackbarOpen(true);
      fetchSummaries(searchQuery);
    } catch (error) {
      console.error('Error deleting selected notes:', error);
      setSnackbarMessage('Error al eliminar las notas seleccionadas. Por favor, intenta de nuevo.');
      setSnackbarOpen(true);
    }
  };

  const handleNoteClick = (note) => {
    if (selectMode) {
      handleNoteSelect(note.timestamp);
    } else {
      setExpandedNote({
        ...note,
        createdAt: note.createdAt,
        lastMessageTimestamp: note.lastMessageTimestamp
      });
    }
  };

  const handleSaveEdit = async (noteId, newContent) => {
    try {
      const response = await editSummary(user.uid, noteId, newContent);
      if (response.success) {
        setSummaries(prevSummaries =>
          prevSummaries.map(summary =>
            summary.timestamp === noteId
              ? { ...summary, summary: newContent }
              : summary
          )
        );
        setSnackbarMessage('Nota actualizada con éxito');
        setSnackbarOpen(true);
      } else {
        throw new Error(response.error || 'Failed to update note');
      }
    } catch (error) {
      console.error('Error saving edited note:', error);
      setSnackbarMessage('Error al guardar la nota. Por favor, intenta de nuevo.');
      setSnackbarOpen(true);
    }
  };

  const handleCopyClick = (note) => {
    navigator.clipboard.writeText(note.summary).then(() => {
      setSnackbarMessage('Contenido copiado al portapapeles');
      setSnackbarOpen(true);
    }).catch(err => {
      console.error('Error al copiar el contenido:', err);
      setSnackbarMessage('Error al copiar el contenido. Por favor, intenta de nuevo.');
      setSnackbarOpen(true);
    });
  };

  const renderNotes = () => {
    const startIndex = pageIndex * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const visibleNotes = summaries.slice(startIndex, endIndex);

    return (
      <AnimatePresence mode="wait" initial={false} custom={direction}>
        <NotasGrid
          key={pageIndex}
          custom={direction}
          variants={{
            enter: (direction) => ({
              x: direction > 0 ? 1000 : -1000,
              opacity: 0,
            }),
            center: {
              x: 0,
              opacity: 1,
            },
            exit: (direction) => ({
              x: direction < 0 ? 1000 : -1000,
              opacity: 0,
            }),
          }}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
        >
          {visibleNotes.map((summary) => (
            <NotaCard 
              key={summary.timestamp}
              summary={summary}
              onClick={() => handleNoteClick(summary)}
              color={summary.color || noteColors[summary.timestamp]}
              isPersonalized={summary.color !== undefined || noteColors[summary.timestamp] !== undefined}
              onColorPickerOpen={handleColorPickerOpen}
              selectMode={selectMode}
              isSelected={selectedNotes.includes(summary.timestamp)}
              onSelect={handleNoteSelect}
            />
          ))}
        </NotasGrid>
      </AnimatePresence>
    );
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" align="center">
        Error: {error}
      </Typography>
    );
  }

  return (
    <NotasContainer>
      <SearchBarContainer>
        <StyledInput
          fullWidth
          variant="standard"
          placeholder="Buscar notas por texto..."
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: 'white' }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {isSearching && <CircularProgress color="inherit" size={20} />}
              </InputAdornment>
            ),
          }}
        />
      </SearchBarContainer>

      <Typography variant="h6" sx={{ marginBottom: 2, color: 'white', textAlign: 'center' }}>
        Mis Notas
      </Typography>

      <Box sx={{ overflow: 'hidden' }}>
        {renderNotes()}
      </Box>

      {selectMode && (
        <SelectionButtons>
          <Button
            variant="contained"
            onClick={handleSelectMode}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              color: 'white',
              borderRadius: '100px',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
              },
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={handleDeleteSelected}
            disabled={selectedNotes.length === 0}
            sx={{
              backgroundColor: 'rgba(255, 0, 0, 0.8)',
              color: 'white',
              borderRadius: '100px',
              '&:hover': {
                backgroundColor: 'rgba(255, 0, 0, 1)',
              },
              '&:disabled': {
                backgroundColor: 'rgba(255, 0, 0, 0.4)',
                color: 'rgba(255, 255, 255, 0.7)',
              },
            }}
          >
            {`Eliminar${selectedNotes.length > 0 ? ` (${selectedNotes.length})` : ''}`}
          </Button>
        </SelectionButtons>
      )}

      <NavigationContainer>
        <NavigationButton 
          onClick={handleBack} 
          disabled={pageIndex === 0}
        >
          <ArrowBackIosIcon />
        </NavigationButton>
        <NavigationButton 
          onClick={handleNext} 
          disabled={pageIndex >= Math.ceil(summaries.length / itemsPerPage) - 1}
        >
          <ArrowForwardIosIcon />
        </NavigationButton>
        <DeleteContainer>
          <IconButton onClick={handleTrashClick}>
          <Trash2 size={24} color="white" /> {}
          </IconButton>
        </DeleteContainer>
      </NavigationContainer>

      <PaletaDeColores 
        anchorEl={colorPickerAnchorEl} 
        open={Boolean(colorPickerAnchorEl)} 
        onClose={() => setColorPickerAnchorEl(null)}
        onColorChange={handleColorChange}
        selectedColor={selectedColor}
      />

      <TrashPanel
        open={Boolean(trashAnchorEl)}
        anchorEl={trashAnchorEl}
        onClose={handleTrashClose}
        onSelectMode={handleSelectMode}
        onDeleteAll={handleDeleteAllClick}
      />

      <ConfirmDeleteAll
        open={confirmDeleteAllOpen}
        onClose={() => setConfirmDeleteAllOpen(false)}
        onConfirm={handleConfirmDeleteAll}
      />

      <NoteViewDialog
        open={!!expandedNote}
        note={expandedNote}
        onClose={() => {
          setExpandedNote(null);
        }}
        onSave={handleSaveEdit}
        onCopy={handleCopyClick}
        user={user}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </NotasContainer>
  );
};

export default Notas;