import React, { useState, useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Provider } from 'react-redux';
import { store } from './components/BackgroundSummaryService';

import LimitedDashboard from './components/LimitedDashboard';
import FullDashboard from './components/FullDashboard';
import UserProfile from './components/UserProfile';

function AppContent() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [backgroundType, setBackgroundType] = useState('odin');
  const [backgroundImage, setBackgroundImage] = useState({
    aurora: "/images/background/aurora.png",
    curious: "/images/background/curious.png",
    edge: "/images/background/edge.png",
    eureka: "/images/background/eureka.png",
    hacker: "/images/background/hacker.png",
    horizon: "/images/background/horizon.png",
    ignite: "/images/background/ignite.png",
    mind: "/images/background/mind.png",
    neuro: "/images/background/neuro.png",
    odin: "/images/background/odin.png",
    python: "/images/background/python.png",
    vibe: "/images/background/vibe.png"
  });
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    if (location.pathname === '/auth/success' && token) {
      localStorage.setItem('token', token);
      navigate('/');
    }
  }, [location, navigate]);

  useEffect(() => {
    const handleUnhandledRejection = (event) => {
      console.error('Unhandled promise rejection:', event.reason);
    };

    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    return () => {
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
    };
  }, []);

  const handleLogin = useCallback((user) => {
    setUser(user);
    navigate('/');
  }, [navigate]);

  const handleLogout = useCallback(() => {
    auth.signOut().then(() => {
      setUser(null);
      localStorage.removeItem('token');
      navigate('/');
    }).catch((error) => {
      console.error('Error al cerrar sesión:', error);
    });
  }, [auth, navigate]);

  const handleBackgroundChange = useCallback((newBackgroundType) => {
    setBackgroundType(newBackgroundType);
  }, []);

  const getCurrentBackground = useCallback(() => {
    return backgroundImage[backgroundType];
  }, [backgroundType, backgroundImage]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{
      display: 'flex',
      minHeight: '100vh',
      backgroundImage: `url(${getCurrentBackground()})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      transition: 'background-image 0.5s ease-in-out',
    }}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Routes>
          <Route path="/" element={
            user ? (
              <FullDashboard 
                user={user}
                onLogout={handleLogout}
                backgroundType={backgroundType}
                onBackgroundChange={handleBackgroundChange}
              />
            ) : (
              <LimitedDashboard 
                onLogin={handleLogin}
              />
            )
          } />
          <Route path="/profile" element={
            user ? (
              <UserProfile 
                user={user} 
                onLogout={handleLogout} 
                onBackgroundChange={handleBackgroundChange}
                backgroundType={backgroundType}
              />
            ) : (
              <Navigate to="/" />
            )
          } />
          <Route path="/auth/success" element={<Navigate to="/" />} />
        </Routes>
      </Box>
    </Box>
  );
}

function App() {
  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#007AFF',
      },
    },
    typography: {
      fontFamily: 'Inter, sans-serif',
      fontWeightMedium: 500,
      fontWeightRegular: 400,
      fontWeightSemiBold: 600,
      fontWeightBold: 700,
    },
  });

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <AppContent />
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;