import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Box, IconButton, Avatar, Fade, useMediaQuery, Snackbar, Alert, Button, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Paintbrush } from 'lucide-react';
import ProfileDashboard from './ProfileDashboard';
import PerspectiveCarousel from './PerspectiveCarousel';
import ChatViewNest from './ChatViewNest';
import Notas from './Notas';
import BackgroundSelector from './BackgroundSelector';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { sendMessageToAGT, getChatHistory, getUserBackgroundPreference, setUserBackgroundPreference } from './AGTService';

const FixedContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  transition: 'background-image 0.5s ease-in-out',
}));

const StyledWindowBar = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: 'transparent',
  backdropFilter: 'blur(40px)',
  color: 'white',
  fontFamily: "'Inter', sans-serif",
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
}));

const TopBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5, 1),
  },
}));

const NavContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    maxWidth: 'calc(100% - 80px)',
    margin: '0 auto',
  },
}));

const ContentArea = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  padding: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const NavigationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(1),
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: '50px',
  margin: '0 auto',
  width: 'fit-content',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5),
    width: '100%',
  },
}));

const SlidingBackground = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 5,
  left: 0,
  height: 'calc(100% - 10px)',
  borderRadius: '50px',
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  transition: 'transform 0.3s ease, width 0.3s ease',
}));

const NavButton = styled(Button)(({ theme }) => ({
  color: 'white',
  margin: theme.spacing(0, 0.5),
  textTransform: 'capitalize',
  borderRadius: '50px',
  zIndex: 1,
  padding: theme.spacing(0.5, 2),
  backgroundColor: 'transparent',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  '&:focus': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0, 0.25),
    fontSize: '0.8rem',
  },
}));

const SidebarNavigation = React.memo(({ onNavigate, activeSection }) => {
  const menuItems = ['Home', 'Chat', 'Notas'];
  const [slideStyle, setSlideStyle] = useState({});
  const buttonRefs = useRef([]);
  const containerRef = useRef(null);

  const updateSlideStyle = useCallback(() => {
    const activeIndex = menuItems.findIndex(
      (item) => activeSection === item.toLowerCase()
    );
    
    if (buttonRefs.current[activeIndex] && containerRef.current) {
      const activeButton = buttonRefs.current[activeIndex];
      const buttonRect = activeButton.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();
      const offsetLeft = buttonRect.left - containerRect.left;

      setSlideStyle({
        width: `${buttonRect.width}px`,
        transform: `translateX(${offsetLeft}px)`,
      });
    }
  }, [activeSection]);

  useEffect(() => {
    const handleResize = () => {
      requestAnimationFrame(updateSlideStyle);
    };

    updateSlideStyle();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [updateSlideStyle]);

  return (
    <NavigationContainer ref={containerRef}>
      <SlidingBackground style={slideStyle} />
      {menuItems.map((item, index) => (
        <NavButton
          key={item}
          ref={(el) => (buttonRefs.current[index] = el)}
          onClick={() => onNavigate(item.toLowerCase())}
          disableRipple
          disableElevation
          disableFocusRipple
        >
          {item}
        </NavButton>
      ))}
    </NavigationContainer>
  );
});

const TopBarContent = ({ handleBackgroundClick, handleProfileClick, user, onNavigate, activeSection }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TopBar>
      <IconButton 
        onClick={handleBackgroundClick} 
        sx={{ 
          color: 'white', 
          padding: isMobile ? '4px' : '8px',
          marginRight: isMobile ? '4px' : '8px',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
          },
        }}
      >
        <Paintbrush size={isMobile ? 20 : 24} />
      </IconButton>
      <NavContainer>
        <SidebarNavigation onNavigate={onNavigate} activeSection={activeSection} />
      </NavContainer>
      <Avatar 
        src={user?.photoURL || "/path-to-default-image.jpg"} 
        alt={user?.displayName || "Profile"} 
        onClick={handleProfileClick}
        sx={{ 
          cursor: 'pointer', 
          width: isMobile ? 32 : 40, 
          height: isMobile ? 32 : 40,
          marginLeft: isMobile ? '4px' : '8px'
        }}
      />
    </TopBar>
  );
};

const FullDashboard = ({ onLogout, user: initialUser, backgroundType: initialBackgroundType, onBackgroundChange }) => {
  const [activeSection, setActiveSection] = useState('chat');
  const [user, setUser] = useState(initialUser);
  const [chatMessages, setChatMessages] = useState([]);
  const [isLoadingChat, setIsLoadingChat] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [showProfile, setShowProfile] = useState(false);
  const [backgroundSelectorAnchorEl, setBackgroundSelectorAnchorEl] = useState(null);
  const [backgroundType, setBackgroundType] = useState(initialBackgroundType || 'odin');
  
  const backgroundImage = useMemo(() => ({
    aurora: "/images/background/aurora.png",
    curious: "/images/background/curious.png",
    edge: "/images/background/edge.png",
    eureka: "/images/background/eureka.png",
    hacker: "/images/background/hacker.png",
    horizon: "/images/background/horizon.png",
    ignite: "/images/background/ignite.png",
    mind: "/images/background/mind.png",
    neuro: "/images/background/neuro.png",
    odin: "/images/background/odin.png",
    python: "/images/background/python.png",
    vibe: "/images/background/vibe.png"
  }), []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        onLogout();
      }
    });
    return () => unsubscribe();
  }, [auth, onLogout]);

  useEffect(() => {
    const loadChatHistory = async () => {
      if (user) {
        setIsLoadingChat(true);
        setErrorMessage('');
        try {
          const history = await getChatHistory(user.uid);
          setChatMessages(history);
        } catch (error) {
          console.error('Error loading chat history:', error);
          setErrorMessage('Failed to load chat history. Please try again later.');
        } finally {
          setIsLoadingChat(false);
        }
      }
    };

    loadChatHistory();
  }, [user]);

  useEffect(() => {
    const loadUserPreference = async () => {
      if (initialUser) {
        try {
          const preference = await getUserBackgroundPreference(initialUser.uid);
          setBackgroundType(preference || 'odin');
          onBackgroundChange(preference || 'odin');
        } catch (error) {
          console.error('Error loading user background preference:', error);
          setBackgroundType('odin');
          onBackgroundChange('odin');
        }
      }
    };
  
    loadUserPreference();
  }, [initialUser, onBackgroundChange]);

  const handleNavigate = useCallback((section) => {
    setActiveSection(section);
    setShowProfile(false);
  }, []);

  const handleProfileClick = useCallback(() => {
    setShowProfile(true);
    setActiveSection('profile');
  }, []);

  const handleSendMessage = async (newMessageText, isUser = true) => {
    const newMessage = { text: newMessageText, isUser: isUser, id: Date.now() };
    setChatMessages((prevMessages) => [...prevMessages, newMessage]);

    if (isUser) {
      handleNavigate('chat');
      try {
        const response = await sendMessageToAGT(newMessageText, user.uid);
        setChatMessages((prevMessages) => [
          ...prevMessages,
          { text: response.reply, isUser: false, id: Date.now() }
        ]);
      } catch (error) {
        console.error('Error sending message to AGT:', error);
        setErrorMessage("Error sending message. Please try again.");
        setChatMessages((prevMessages) => [
          ...prevMessages,
          { text: "Lo siento, ha ocurrido un error.", isUser: false, id: Date.now() }
        ]);
      }
    }
  };

  const handleBackgroundClick = (event) => {
    setBackgroundSelectorAnchorEl(event.currentTarget);
  };

  const handleBackgroundClose = () => {
    setBackgroundSelectorAnchorEl(null);
  };

  const handleBackgroundChange = useCallback(async (newBackgroundType) => {
    try {
      await setUserBackgroundPreference(user.uid, newBackgroundType);
      setBackgroundType(newBackgroundType);
      onBackgroundChange(newBackgroundType);
    } catch (error) {
      console.error('Error setting user background preference:', error);
      setErrorMessage("Error al guardar la preferencia de fondo. Por favor, intenta de nuevo.");
    }
  }, [user, onBackgroundChange]);

  const getCurrentBackground = useCallback(() => {
    return backgroundImage[backgroundType] || backgroundImage['odin'];
  }, [backgroundType, backgroundImage]);

  const renderContent = () => {
    switch (activeSection) {
      case 'home':
        return (
          <Box sx={{ flexGrow: 1, overflow: 'hidden', mb: 2, width: '100%' }}>
            <PerspectiveCarousel />
          </Box>
        );
      case 'chat':
        return (
          <Box sx={{ flexGrow: 1, overflowY: 'auto', width: '100%', mb: 2, display: 'flex', flexDirection: 'column' }}>
            <ChatViewNest 
              user={user}
              onSendMessage={handleSendMessage}
              isLimited={false}
              initialView={false}
              messages={chatMessages}
              isLoading={isLoadingChat}
              onNavigate={handleNavigate}
            />
          </Box>
        );
      case 'notas':
        return (
          <Box sx={{ flexGrow: 1, width: '100%', height: '100%', overflowY: 'auto' }}>
            <Notas user={user} />
          </Box>
        );
      case 'profile':
        return (
          <ProfileDashboard 
            user={user}
            onLogout={onLogout} 
            backgroundType={backgroundType}
          />
        );
      default:
        return null;
    }
  };

  return (
    <FixedContainer style={{ backgroundImage: `url(${getCurrentBackground()})` }}>
      <StyledWindowBar>
        <TopBarContent 
          handleBackgroundClick={handleBackgroundClick}
          handleProfileClick={handleProfileClick}
          user={user}
          onNavigate={handleNavigate}
          activeSection={activeSection}
        />
        <ContentArea>
          <Fade in={true} timeout={300}>
            <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
              {renderContent()}
            </Box>
          </Fade>
          </ContentArea>
      </StyledWindowBar>
      <BackgroundSelector
        anchorEl={backgroundSelectorAnchorEl}
        open={Boolean(backgroundSelectorAnchorEl)}
        onClose={handleBackgroundClose}
        onBackgroundChange={handleBackgroundChange}
        currentBackground={backgroundType}
      />
      <Snackbar 
        open={!!errorMessage} 
        autoHideDuration={6000} 
        onClose={() => setErrorMessage('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </FixedContainer>
  );
};

export default FullDashboard;