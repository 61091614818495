import React, { useState } from 'react';
import { Box, Typography, IconButton, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { ChevronLeft, ChevronRight, ArrowLeft } from 'lucide-react';
import DOMPurify from 'dompurify';

const CarouselContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
}));

const CardsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'calc(100% - 70px)',
  width: '100%',
  position: 'relative',
  perspective: '1000px',
}));

const Card = styled(Box)(({ theme, position, isSingleCardView }) => ({
  position: 'absolute',
  width: '250px',  // Fixed width for all screen sizes
  height: '300px', // Fixed height for all screen sizes
  transition: 'all 0.5s ease',
  transformStyle: 'preserve-3d',
  ...(isSingleCardView
    ? {
        transform: position === 'center' ? 'translateX(0)' : 'translateX(100vw)',
        opacity: position === 'center' ? 1 : 0,
      }
    : {
        ...(position === 'center' && {
          zIndex: 3,
          transform: 'scale(1.2) translateZ(0)',
        }),
        ...(position === 'left' && {
          zIndex: 2,
          transform: 'translateX(-60%) scale(0.9) translateZ(-100px) rotateY(30deg)',
        }),
        ...(position === 'right' && {
          zIndex: 2,
          transform: 'translateX(60%) scale(0.9) translateZ(-100px) rotateY(-30deg)',
        }),
        ...(position === 'far-left' && {
          zIndex: 1,
          transform: 'translateX(-120%) scale(0.8) translateZ(-200px) rotateY(30deg)',
        }),
        ...(position === 'far-right' && {
          zIndex: 1,
          transform: 'translateX(120%) scale(0.8) translateZ(-200px) rotateY(-30deg)',
        }),
      }),
}));

const CardContent = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: '15px',
  borderRadius: '20px',
  overflow: 'hidden',
  backgroundColor: 'rgba(128, 128, 128, 0.3)',
  backdropFilter: 'blur(12px)',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const CardImage = styled('img')({
  width: '100%',
  height: 'auto',
  objectFit: 'contain',
  marginBottom: '10px',
  maxHeight: 'calc(100% - 40px)',
});

const NavigationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100px',
  height: '50px',
  marginTop: '20px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(20px)',
  borderRadius: '25px',
  border: '1px solid rgba(255, 255, 255, 0.2)',
}));

const NavigationButton = styled(IconButton)({
  color: 'white',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
});

const TextView = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '30px',
  backdropFilter: 'blur(12px)',
  borderRadius: '20px',
  color: 'white',
  overflowY: 'auto',
}));

const TextContent = styled(Box)(({ theme }) => ({
  maxWidth: '600px',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    maxWidth: '800px',
  },
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '300px',
  marginBottom: '20px',
  borderRadius: '10px',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    maxWidth: '100%',
    borderRadius: '20px',
    marginBottom: '30px',
  },
}));

const TextImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
  [theme.breakpoints.up('md')]: {
    maxHeight: '400px',
    objectFit: 'contain',
  },
}));

const Body = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  lineHeight: '1.6',
  textAlign: 'justify',
  '& p:first-of-type': {
    marginTop: 0,
  },
  '& b': {
    fontWeight: 'bold',
  },
  '& i': {
    fontStyle: 'italic',
  },
  '& u': {
    textDecoration: 'underline',
  },
  '& mark': {
    backgroundColor: 'yellow',
    color: 'black',
  },
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '20px',
  left: '20px',
  color: 'white',
}));

const PerspectiveCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(2);
  const [showText, setShowText] = useState(false);
  const theme = useTheme();
  const isSingleCardView = useMediaQuery('(max-width:800px), (max-height:480px)');
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const cards = [
    { 
      image: '/images/notification/feedback.png', 
      textImage: '/images/notification/feedback.png',
      title: 'Colors',
      text: `<p>
<strong>La vida es mejor en colores ¿No?<strong>

Ahora puedes personalizar tu AGT a tu gusto. Escoge entre 12 fondos de pantalla disponibles; el qué más te guste.

No sabía que lo necesitaba hasta que lo vi.</p>`
    },
    { 
      image: '/images/notification/agtsearch.png', 
      textImage: '/images/notification/agtsearch.png',
      textImageSmall: '/images/notification/agtsearch.png',
      title: 'AGT Maker',
      text: `<p><strong>AGT Maker: Diagramas, esquemas y mapas mentales.</strong> 
      
      ¿Ordenar ideas? ¿Crear mapas mentales? Simplemente di: “Crea un diagrama en AGT Maker.”</p>

<p><strong>AGT Maker</strong> está en su versión beta. Esperamos tus comentarios para seguir mejorando.</p>
`
    },
    { 
      image: '/images/notification/whats-new.png', 
      textImage: '/images/notification/Page-good.png',
      title: 'Good news!',
      text: `
<p><strong>¡Grandes noticias!</strong></p>

<p>Hemos realizado mejoras para elevar tu experiencia, corrigiendo errores en la interfaz de usuario y solucionando problemas en las funcionalidades del chat. ¡Disfruta de esta nueva versión de AGT!</p>

<p><strong>¡AGT Maker!</strong> Genera diagramas, esquemas y mucho más.</p>

<p><strong>AGT:</strong> ¡Estamos preparando funconalidades para que puedas subir documentos, imágenes y todo lo que necesites! Aquellos que participaron en nuestra encuesta accederán a un nivel Pro para su prueba gratuita.</p>

<p><strong>AGT Edu + AURA:</strong> El gran lanzamiento será en octubre. Estamos preparando todo, y el equipo está trabajando arduamente. En el Instagram de DirectiveAI estaremos compartiendo adelantos sobre su apariencia y funcionalidades. Además, durante el lanzamiento de AGT Edu + AURA, habrá sorpresas para los espectadores: ¡podrás ganar premios en Uber! Y, como si fuera poco, habrá un premio extra para el usuario más activo.</p>

<p>La próxima actualización llegará el <strong>12 de octubre</strong>. ¡Mantente atento a todo lo que viene en @directiveai!</p>
`
    },
    { 
      image: '/images/notification/whoisagt.png', 
      textImage: '/images/notification/Page-whoisagt.png',
      title: `Say hello to AGT`,
      text: `<p><strong>AGT ya está aquí.</strong> 
      
      ¿Tienes preguntas? AGT tiene respuestas. 
      
      Descubre qué es AGT, quién lo creó y cómo funciona. ¿Te interesa saber más sobre DirectiveAI y su desarrollo? ¡Adelante, las respuestas están a tu alcance! AGT está listo para ayudarte con tus dudas, solicitudes y mucho más. ¡No dudes en preguntar y descubrir todo lo que AGT puede hacer por ti!</p>`
    },
    { 
      image: '/images/notification/welcome-to-notes.png', 
      textImage: '/images/notification/Page-Notes.png',
      title: 'Introducing Notes',
      text: `<p><strong>Con Notas, captura y organiza tus pensamientos de forma impecable.</strong> Además, obtén resúmenes automáticos de tus conversaciones cuando lo necesites. Con <strong>resúmenes inteligentes editables</strong>, búsqueda por palabras clave, etiquetas personalizables y una <strong>potente integración con AGT</strong>, tendrás todo bajo control. No importa cuánto tiempo haya pasado, podrás regresar a tus notas para ver los puntos clave y monitorear tu progreso sin tener que releer todo.</p>

<p><strong>Lo mejor de todo es que Notas es completamente gratis para usuarios registrados.</strong> Regístrate hoy y asegura tu <strong>acceso gratuito de por vida</strong>, incluso si esta función llega a ser de pago en el futuro.</p>
`
    },
  ];

  const getCardPosition = (index) => {
    if (isSingleCardView) {
      return index === activeIndex ? 'center' : 'hidden';
    }
    const diff = index - activeIndex;
    if (diff === 0) return 'center';
    if (diff === 1 || diff === -4) return 'right';
    if (diff === -1 || diff === 4) return 'left';
    if (diff === 2 || diff === -3) return 'far-right';
    return 'far-left';
  };

  const handlePrevious = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + cards.length) % cards.length);
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % cards.length);
  };

  const handleCardClick = () => {
    setShowText(true);
  };

  const handleBackClick = () => {
    setShowText(false);
  };

  const createMarkup = (html) => {
    return {__html: DOMPurify.sanitize(html)};
  }

  return (
    <CarouselContainer>
      {showText ? (
        <TextView>
          <BackButton onClick={handleBackClick}>
            <ArrowLeft />
          </BackButton>
          <TextContent>
            <ImageContainer>
              <TextImage 
                src={cards[activeIndex].textImage} 
                alt={cards[activeIndex].title}
                style={isDesktop ? { width: '100%', height: 'auto' } : {}}
              />
            </ImageContainer>
            <Body>
              {cards[activeIndex].text.split('\n').map((paragraph, index) => (
                <p key={index} dangerouslySetInnerHTML={createMarkup(paragraph)} />
              ))}
            </Body>
          </TextContent>
        </TextView>
      ) : (
        <CardsContainer>
          {cards.map((card, index) => (
            <Card 
              key={index} 
              position={getCardPosition(index)} 
              isSingleCardView={isSingleCardView}
              onClick={index === activeIndex ? handleCardClick : undefined}
              style={{ cursor: index === activeIndex ? 'pointer' : 'default' }}
            >
              <CardContent>
                <CardImage src={card.image} alt={card.title} />
                <Typography variant="subtitle2" color="white" align="center">
                  {card.title}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </CardsContainer>
      )}
      <NavigationContainer>
        <NavigationButton onClick={handlePrevious}>
          <ChevronLeft />
        </NavigationButton>
        <NavigationButton onClick={handleNext}>
          <ChevronRight />
        </NavigationButton>
      </NavigationContainer>
    </CarouselContainer>
  );
};

export default PerspectiveCarousel;