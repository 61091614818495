import React, { useState, useEffect } from 'react';
import { Box, Button, Fade, Snackbar, Alert, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ArrowLeft } from 'lucide-react';
import Auth from './Auth';
import PerspectiveCarousel from './PerspectiveCarousel';

const FixedContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  transition: 'background-image 0.5s ease-in-out',
}));

const StyledWindowBar = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(128, 128, 128, 0)',
  backdropFilter: 'blur(40px)',
  color: 'white',
  fontFamily: "'Inter', sans-serif",
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
}));

const TopBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
}));

const ContentArea = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  padding: theme.spacing(3),
}));

const AuthButton = styled(Button)(({ theme, isLogin }) => ({
  borderRadius: '30px',
  padding: theme.spacing(1, 3),
  margin: theme.spacing(0, 0.5),
  backgroundColor: isLogin ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)',
  color: isLogin ? 'white' : 'black',
  '&:hover': {
    backgroundColor: isLogin ? 'rgba(0, 0, 0, 0.7)' : 'rgba(255, 255, 255, 0.7)',
  },
}));

const backgroundImages = {
  aurora: "/images/background/aurora.png",
  curious: "/images/background/curious.png",
  edge: "/images/background/edge.png",
  eureka: "/images/background/eureka.png",
  hacker: "/images/background/hacker.png",
  horizon: "/images/background/horizon.png",
  ignite: "/images/background/ignite.png",
  mind: "/images/background/mind.png",
  neuro: "/images/background/neuro.png",
  odin: "/images/background/odin.png",
  python: "/images/background/python.png",
  vibe: "/images/background/vibe.png"
};

const LimitedDashboard = ({ onLogout, user: initialUser }) => {
  const [user, setUser] = useState(initialUser);
  const [errorMessage, setErrorMessage] = useState('');
  const [showAuth, setShowAuth] = useState(false);
  const [authMode, setAuthMode] = useState('login');
  const [currentBackground, setCurrentBackground] = useState(backgroundImages.odin);
  
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setShowAuth(false);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, [auth]);

  const handleAuthClick = (mode) => {
    setAuthMode(mode);
    setShowAuth(true);
  };

  const handleAuthClose = () => {
    setShowAuth(false);
  };

  return (
    <FixedContainer style={{ backgroundImage: `url(${currentBackground})` }}>
      <StyledWindowBar>
        <TopBar>
          <Box>
            {showAuth && (
              <IconButton color="inherit" onClick={handleAuthClose}>
                <ArrowLeft />
              </IconButton>
            )}
          </Box>
          <Box>
            {!user && !showAuth && (
              <>
                <AuthButton isLogin onClick={() => handleAuthClick('login')}>Login</AuthButton>
                <AuthButton onClick={() => handleAuthClick('signup')}>Signup</AuthButton>
              </>
            )}
            {user && (
              <AuthButton isLogin onClick={onLogout}>Logout</AuthButton>
            )}
          </Box>
        </TopBar>
        <ContentArea>
          <Fade in={true} timeout={300}>
            <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
              {showAuth ? (
                <Auth mode={authMode} onClose={handleAuthClose} />
              ) : (
                <Box sx={{ flexGrow: 1, overflow: 'hidden', mb: 2, width: '100%' }}>
                  <PerspectiveCarousel />
                </Box>
              )}
            </Box>
          </Fade>
        </ContentArea>
      </StyledWindowBar>
      <Snackbar 
        open={!!errorMessage} 
        autoHideDuration={6000} 
        onClose={() => setErrorMessage('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </FixedContainer>
  );
};

export default LimitedDashboard;