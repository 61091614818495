import React, { useState, useEffect, useMemo } from 'react';
import { Popover, Box, IconButton, Typography, styled } from '@mui/material';

const BackgroundButton = styled(IconButton)(({ theme, selected }) => ({
  width: 40,
  height: 40,
  padding: 0,
  margin: 4,
  borderRadius: '50%',
  border: selected ? `2px solid white` : 'none',
  overflow: 'hidden',
  '&:hover': {
    opacity: 0.8,
  },
  position: 'relative',
}));

const BackgroundImage = styled('div')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  transition: 'opacity 0.3s ease-in-out',
});

const backgrounds = [
  { name: 'Aurora', type: 'aurora', image: "/images/IconBackground/aurora.png" },
  { name: 'Curious', type: 'curious', image: "/images/IconBackground/curious.png" },
  { name: 'Edge', type: 'edge', image: "/images/IconBackground/edge.png" },
  { name: 'Eureka', type: 'eureka', image: "/images/IconBackground/eureka.png" },
  { name: 'Hacker', type: 'hacker', image: "/images/IconBackground/hacker.png" },
  { name: 'Horizon', type: 'horizon', image: "/images/IconBackground/horizon.png" },
  { name: 'Ignite', type: 'ignite', image: "/images/IconBackground/ignite.png" },
  { name: 'Mind', type: 'mind', image: "/images/IconBackground/mind.png" },
  { name: 'Neuro', type: 'neuro', image: "/images/IconBackground/neuro.png" },
  { name: 'Odin', type: 'odin', image: "/images/IconBackground/odin.png" },
  { name: 'Python', type: 'python', image: "/images/IconBackground/python.png" },
  { name: 'Vibe', type: 'vibe', image: "/images/IconBackground/vibe.png" }
];

const BackgroundSelector = ({ anchorEl, open, onClose, onBackgroundChange, currentBackground }) => {
  const [loadedImages, setLoadedImages] = useState({});
  const [hoveredBackground, setHoveredBackground] = useState(null);

  const preloadedImages = useMemo(() => {
    const images = {};
    backgrounds.forEach(bg => {
      const img = new Image();
      img.src = bg.image;
      images[bg.type] = img;
    });
    return images;
  }, []);

  useEffect(() => {
    backgrounds.forEach((bg) => {
      if (preloadedImages[bg.type].complete) {
        setLoadedImages(prev => ({ ...prev, [bg.type]: true }));
      } else {
        preloadedImages[bg.type].onload = () => setLoadedImages(prev => ({ ...prev, [bg.type]: true }));
      }
    });
  }, [preloadedImages]);

  useEffect(() => {
    if (open) {
      backgrounds.forEach((bg) => {
        const img = new Image();
        img.src = bg.image;
      });
    }
  }, [open]);

  const handleBackgroundChange = (bgType) => {
    if (loadedImages[bgType]) {
      onBackgroundChange(bgType);
    }
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          maxHeight: 400,
          width: '300px',
          backgroundColor: 'rgba(128, 128, 128, 0.3)',
          backdropFilter: 'blur(12px)',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
          padding: '15px',
        },
      }}
    >
      <Typography variant="h6" sx={{ color: 'white', marginBottom: 2, textAlign: 'center' }}>
        Choose your Color
      </Typography>
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 2 }}>
        {backgrounds.map((bg) => (
          <Box key={bg.type} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <BackgroundButton
              onClick={() => handleBackgroundChange(bg.type)}
              onMouseEnter={() => setHoveredBackground(bg.type)}
              onMouseLeave={() => setHoveredBackground(null)}
              selected={currentBackground === bg.type}
            >
              <BackgroundImage
                style={{
                  backgroundImage: `url(${bg.image})`,
                  opacity: loadedImages[bg.type] ? 1 : 0,
                }}
              />
              <BackgroundImage
                style={{
                  backgroundImage: 'linear-gradient(45deg, #f3f3f3 25%, #e3e3e3 25%, #e3e3e3 50%, #f3f3f3 50%, #f3f3f3 75%, #e3e3e3 75%, #e3e3e3 100%)',
                  backgroundSize: '10px 10px',
                  opacity: loadedImages[bg.type] ? 0 : 1,
                }}
              />
              {hoveredBackground === bg.type && !loadedImages[bg.type] && (
                <Box sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  color: 'white',
                  fontSize: '10px',
                }}>
                  Cargando...
                </Box>
              )}
            </BackgroundButton>
            <Typography variant="caption" sx={{ color: 'white', fontSize: '0.7rem', marginTop: '4px' }}>
              {bg.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </Popover>
  );
};

export default BackgroundSelector;