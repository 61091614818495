import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Container, CircularProgress, Avatar } from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';

const UserProfile = ({ onLogout }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const auth = getAuth();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = auth.currentUser;
        if (currentUser) {
          const idTokenResult = await currentUser.getIdTokenResult();
          setUser({
            name: currentUser.displayName,
            email: currentUser.email,
            provider: idTokenResult.signInProvider,
            photoURL: currentUser.photoURL,
            // Agrega más campos según sea necesario
          });
        } else {
          setError('No hay sesión activa');
        }
      } catch (error) {
        setError('Error al obtener la información del usuario');
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [auth]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      onLogout();
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
      setError('Error al cerrar sesión');
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!user) return <Typography>No se encontró información del usuario</Typography>;

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar
          src={user.photoURL}
          alt={user.name}
          sx={{ width: 100, height: 100, mb: 2 }}
        />
        <Typography component="h1" variant="h5">
          Perfil de Usuario
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Typography>Nombre: {user.name}</Typography>
          <Typography>Email: {user.email}</Typography>
          <Typography>Proveedor de autenticación: {user.provider}</Typography>
        </Box>
        <Button
          fullWidth
          variant="contained"
          onClick={handleLogout}
          sx={{ mt: 3, mb: 2 }}
        >
          Cerrar Sesión
        </Button>
      </Box>
    </Container>
  );
};

export default UserProfile;