import React, { useState, useEffect, useCallback, useRef } from 'react';
import { IconButton, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Mic, X, Check } from 'lucide-react';

const VoiceRecognitionCard = styled(Box)(({ theme }) => ({
  width: '90%',
  maxWidth: '600px',
  position: 'fixed',
  left: '50%',
  bottom: '20px',
  transform: 'translateX(-50%)',
  borderRadius: '30px',
  padding: '20px',
  transition: 'box-shadow 0.3s, border 0.3s',
  backgroundColor: 'rgba(128, 128, 128, 0.3)',
  backdropFilter: 'blur(12px)',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,

  '&:hover': {
    boxShadow: '0 6px 8px rgba(0, 0, 0, 0.2)',
    border: '1px solid rgba(255, 255, 255, 0.4)',
  },
}));

const VoiceRecognition = ({ onTranscript, isDisabled, inputRef, isListening, setIsListening }) => {
  const recognitionRef = useRef(null);
  const lastTranscriptRef = useRef('');
  const timeoutRef = useRef(null);
  const wordsRef = useRef([]);

  const resetTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      if (wordsRef.current.length === 0) {
        stopListening();
      }
    }, 5000); // Se apaga después de 5 segundos sin detectar sonido
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window)) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = true;
      recognitionRef.current.lang = 'es-ES';

      recognitionRef.current.onresult = (event) => {
        resetTimeout();
        const currentTranscriptIndex = event.results.length - 1;
        const currentTranscript = event.results[currentTranscriptIndex][0].transcript.trim();
        
        const words = currentTranscript.split(' ');
        const newWords = words.slice(wordsRef.current.length);
        
        if (newWords.length > 0) {
          wordsRef.current = words;
          onTranscript(newWords.join(' ') + ' ');
        }

        if (event.results[currentTranscriptIndex].isFinal) {
          lastTranscriptRef.current = currentTranscript;
          wordsRef.current = [];
        }
      };

      recognitionRef.current.onerror = (event) => {
        console.error('Speech recognition error', event.error);
        setIsListening(false);
      };

      recognitionRef.current.onend = () => {
        setIsListening(false);
      };

      recognitionRef.current.onaudiostart = resetTimeout;
    } else {
      console.error('Speech recognition is not supported in this browser.');
    }

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.abort();
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [onTranscript, setIsListening, resetTimeout]);

  useEffect(() => {
    if (!isListening && recognitionRef.current) {
      recognitionRef.current.stop();
      lastTranscriptRef.current = '';
      wordsRef.current = [];
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
  }, [isListening]);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      if (isListening) {
        inputRef.current.style.display = 'none';
      } else {
        inputRef.current.style.display = 'block';
      }
    }
  }, [isListening, inputRef]);

  const startListening = useCallback(() => {
    if (recognitionRef.current) {
      recognitionRef.current.start();
      setIsListening(true);
      resetTimeout();
    }
  }, [setIsListening, resetTimeout]);

  const stopListening = useCallback(() => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      lastTranscriptRef.current = '';
      wordsRef.current = [];
      setIsListening(false);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
  }, [setIsListening]);

  const handleCancelListening = useCallback(() => {
    stopListening();
    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
    }
    onTranscript('', true);
    wordsRef.current = [];
  }, [stopListening, onTranscript, inputRef]);

  const handleConfirmTranscript = useCallback(() => {
    stopListening();
    onTranscript(lastTranscriptRef.current);
    wordsRef.current = [];
  }, [stopListening, onTranscript]);

  const renderSoundWave = (count) => (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', height: '30px', margin: '20px 0' }}>
      {[...Array(count)].map((_, index) => (
        <Box
          key={index}
          sx={{
            width: '2%',
            height: '100%',
            backgroundColor: 'white',
            margin: '0 1px',
            borderRadius: '2px',
            animation: 'soundWave 0.5s infinite alternate',
            animationDelay: `${index * 0.05}s`,
            '@keyframes soundWave': {
              '0%': { 
                transform: 'scaleY(0.3)',
                opacity: 0.3
              },
              '100%': { 
                transform: 'scaleY(1)',
                opacity: 1
              },
            },
          }}
        />
      ))}
    </Box>
  );

  const renderListeningUI = () => (
    <VoiceRecognitionCard>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '10px' }}>
        <IconButton
          onClick={handleCancelListening}
          sx={{
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
          }}
        >
          <X size={24} />
        </IconButton>
        <Typography variant="body1" sx={{ color: 'white', alignSelf: 'center' }}>
          Escuchando...
        </Typography>
        <IconButton
          onClick={handleConfirmTranscript}
          sx={{
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
          }}
        >
          <Check size={24} />
        </IconButton>
      </Box>
      {renderSoundWave(25)}
    </VoiceRecognitionCard>
  );

  return (
    <Box sx={{ position: 'relative', height: '100%', display: 'flex', alignItems: 'center' }}>
      {!isListening ? (
        <IconButton
          onClick={startListening}
          disabled={isDisabled}
          sx={{
            color: 'white',
            padding: '8px',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
          }}
        >
          <Mic size={24} />
        </IconButton>
      ) : renderListeningUI()}
    </Box>
  );
};

export default VoiceRecognition;