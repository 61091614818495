import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Box, 
  Button, 
  List, 
  ListItem, 
  ListItemText, 
  Typography, 
  IconButton, 
  CircularProgress,
  Snackbar,
  Alert,
  Avatar,
  useMediaQuery,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Drawer
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Mic, ArrowUp, X, Check, RefreshCw } from 'lucide-react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import DeleteIcon from '@mui/icons-material/Delete';
import { debounce } from 'lodash';
import { FixedSizeList as VirtualizedList } from 'react-window';
import { 
  getChats, 
  getChatMessages, 
  sendMessageToAGT, 
  createNewChat, 
  getChatContext, 
  updateChatTitle,
  deleteChat
} from './AGTService';
import { generateSummaryInBackground, selectSummaryStatus, markAsHandled, clearSummaryStatus } from './BackgroundSummaryService';
import ThinkingIcon from './ThinkingIcon';
import { ReactComponent as MagicPencilIcon } from '../assets/images/Magic pencil A.svg';
import { ReactComponent as BgExtendIcon } from '../assets/images/Bg extend.svg';
import PromptCards from './PromptCards';
import MagicalMessage from './MagicalMessage';
import VoiceRecognition from './VoiceRecognition';
import AGTMaker from './AGTMaker';
import OptimizedInput from './OptimizedInput';
import InlineImageSearch from './InlineImageSearch';

const ChatContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: '20px',
  overflow: 'hidden',
  position: 'relative',
}));

const Sidebar = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$isOpen',
})(({ theme, $isOpen }) => ({
  width: $isOpen ? '250px' : '0',
  height: '100%',
  borderRight: '1px solid rgba(255, 255, 255, 0.2)',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  backdropFilter: 'blur(20px)',
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  zIndex: 1200,
  transition: 'width 0.3s ease',
  overflow: 'hidden',
}));

const SidebarHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
});

const ChatList = styled(List)({
  flexGrow: 1,
  overflow: 'auto',
  paddingTop: '16px',
});

const ChatArea = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$isSidebarOpen' && prop !== '$isMobile',
})(({ theme, $isSidebarOpen, $isMobile }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  transition: 'padding-left 0.3s ease, opacity 0.3s ease-in-out',
  paddingTop: '56px',
  opacity: 1,
  '&.loading': {
    opacity: 0.7,
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: $isSidebarOpen ? '250px' : '0',
  },
}));

const MessagesContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  width: '100%',
  maxWidth: '800px',
  padding: '20px',
  
  [theme.breakpoints.up('md')]: {
    padding: '20px 0',
  },
}));

const MessageBarContainer = styled(Box)(({ theme }) => ({
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'sticky',
  bottom: 0,
  width: '100%',
  maxWidth: '800px',
  margin: '0 auto',
}));

const InputContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '30px',
  padding: '8px 16px',
}));

const GenerateNoteButton = styled(Button)(({ theme }) => ({
  borderRadius: '30px',
  backdropFilter: 'blur(10px)',
  backgroundColor: 'rgba(255, 255, 255, 0.3)',
  color: 'white',
  padding: '6px 16px',
  fontSize: '0.875rem',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
  },
  '& .MuiButton-startIcon': {
    marginRight: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: 'unset',
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    padding: 0,
  },
}));

const InfoText = styled(Typography)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.5)',
  fontSize: '0.75rem',
  marginTop: '8px',
}));

const MobileHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1100,
}));

const DesktopHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1100,
}));

const ErrorMessage = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  margin: theme.spacing(2, 0),
}));

const RetryButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: 'white',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  borderRadius: '30px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
}));

const ChatViewNest = React.memo(({ user }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  
  const [isSidebarOpen, setIsSidebarOpen] = useState(() => !isMobile);
  const [chats, setChats] = useState([]);
  const [currentChatId, setCurrentChatId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [visibleMessages, setVisibleMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chatContext, setChatContext] = useState({});
  const [chatTitle, setChatTitle] = useState('');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [menuChatId, setMenuChatId] = useState(null);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [isNewChatView, setIsNewChatView] = useState(true);
  const [isListening, setIsListening] = useState(false);
  const [retryAction, setRetryAction] = useState(null);
  const [lastMessageId, setLastMessageId] = useState(null);
  const [lastImageRequestId, setLastImageRequestId] = useState(null);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const chatCache = useRef({});
  const imageSearchCache = useRef({});

  const [isAGTMakerOpen, setIsAGTMakerOpen] = useState(false);
  const [currentAGTContent, setCurrentAGTContent] = useState('');
  const [agtMakerReadyCallback, setAGTMakerReadyCallback] = useState(null);

  const summaryStatus = useSelector(state => selectSummaryStatus(state, currentChatId));

  const [loadingStates, setLoadingStates] = useState({
    messages: false,
    chatInfo: false
  });

  const handleOpenAGTMaker = useCallback((content, readyCallback) => {
    setCurrentAGTContent(content);
    setIsAGTMakerOpen(true);
    setAGTMakerReadyCallback(() => readyCallback);
  }, []);

  const handleCloseAGTMaker = useCallback(() => {
    setIsAGTMakerOpen(false);
    setCurrentAGTContent('');
    setAGTMakerReadyCallback(null);
  }, []);

  const handleAGTMakerContentReady = useCallback(() => {
    if (agtMakerReadyCallback) {
      agtMakerReadyCallback();
      setAGTMakerReadyCallback(null);
    }
  }, [agtMakerReadyCallback]);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [visibleMessages, scrollToBottom]);

  useEffect(() => {
    setIsSidebarOpen(!isMobile);
  }, [isMobile]);

  const saveCurrentChatId = useCallback((chatId) => {
    if (chatId) {
      localStorage.setItem('currentChatId', chatId);
    } else {
      localStorage.removeItem('currentChatId');
    }
  }, []);

  const loadChats = useCallback(async () => {
    if (!user || !user.uid) return;
    setIsLoading(true);
    setError(null);
    try {
      const loadedChats = await getChats(user.uid);
      const sortedChats = Array.isArray(loadedChats)
        ? loadedChats
            .filter(chat => chat && chat.id)
            .map(chat => ({
              ...chat,
              name: chat.title || "New chat"
            }))
            .sort((a, b) => new Date(b.updatedAt || b.createdAt) - new Date(a.updatedAt || a.createdAt))
        : [];
      setChats(sortedChats);
    } catch (error) {
      console.error('Error loading chats:', error);
      setError('Error loading chats. Please try again.');
      setRetryAction(() => loadChats);
      setChats([]);
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  const debouncedLoadChats = useMemo(
    () => debounce(loadChats, 300),
    [loadChats]
  );

  useEffect(() => {
    if (user && user.uid) {
      if (isInitialLoad) {
        loadChats();
        setIsInitialLoad(false);
      } else {
        debouncedLoadChats();
      }
    }
  }, [user, isInitialLoad, debouncedLoadChats, loadChats]);

  useEffect(() => {
    if (!user || !user.uid) return;

    const updateChats = async () => {
      try {
        const updatedChats = await getChats(user.uid);
        const sortedChats = updatedChats
          .map(chat => ({
            ...chat,
            name: chat.title || "New chat"
          }))
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setChats(sortedChats);
        if (currentChatId) {
          const currentChat = sortedChats.find(chat => chat.id === currentChatId);
          if (currentChat) {
            setChatTitle(currentChat.title || "New chat");
          }
        }
      } catch (error) {
        console.error('Error updating chats:', error);
        setError('Error updating chats. Please try again.');
      }
    };

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        updateChats();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [user, currentChatId]);

  useEffect(() => {
    const savedChatId = localStorage.getItem('currentChatId');
    if (savedChatId && user && user.uid) {
      selectChat(savedChatId);
    }
  }, [user]);

  const loadChatContext = useCallback(async (chatId) => {
    if (!user || !user.uid) return;
    try {
      const context = await getChatContext(user.uid, chatId);
      setChatContext(context || {});
    } catch (error) {
      console.error('Error loading chat context:', error);
      setError('Error loading chat context. Some conversation history may be missing.');
      setRetryAction(() => () => loadChatContext(chatId));
    }
  }, [user]);

  const selectChat = useCallback(async (chatId) => {
    if (!user || !user.uid || chatId === currentChatId) return;
    
    setIsAGTMakerOpen(false);
    setCurrentAGTContent('');

    setCurrentChatId(chatId);
    saveCurrentChatId(chatId);
    setLoadingStates({ messages: true, chatInfo: true });
    setError(null);
    setIsNewChatView(false);
    
    try {
      let chatMessages, context;
      
      if (chatCache.current[chatId]) {
        chatMessages = chatCache.current[chatId].messages;
        context = chatCache.current[chatId].context;
      } else {
        [chatMessages, context] = await Promise.all([
          getChatMessages(user.uid, chatId),
          getChatContext(user.uid, chatId)
        ]);
        chatCache.current[chatId] = { messages: chatMessages, context };
      }
      setMessages(chatMessages || []);
      setVisibleMessages((chatMessages || []).slice(-20));
      setChatContext(context || {});
      const selectedChat = chats.find(chat => chat.id === chatId);
      if (selectedChat) {
        setChatTitle(selectedChat.title || "New chat");
      } else {
        const updatedChats = await getChats(user.uid);
        const updatedSelectedChat = updatedChats.find(chat => chat.id === chatId);
        setChatTitle(updatedSelectedChat ? (updatedSelectedChat.title || "New chat") : "New chat");
        setChats(updatedChats
          .map(chat => ({
            ...chat,
            name: chat.title || "New chat"
          }))
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        );
      }
    } catch (error) {
      console.error('Error selecting chat:', error);
      setError('Failed to load chat data. Please try again.');
      setRetryAction(() => () => selectChat(chatId));
      setMessages([]);
      setVisibleMessages([]);
      setChatContext({});
    } finally {
      setLoadingStates({ messages: false, chatInfo: false });
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  }, [user, chats, currentChatId, saveCurrentChatId]);

  const loadMoreMessages = useCallback(() => {
    const currentLength = visibleMessages.length;
    const newMessages = messages.slice(-currentLength - 20, -currentLength);
    setVisibleMessages(prevMessages => [...newMessages, ...prevMessages]);
  }, [messages, visibleMessages]);

  const createNewChatHandler = useCallback(() => {
    setCurrentChatId(null);
    saveCurrentChatId(null);
    setMessages([]);
    setVisibleMessages([]);
    setChatContext({});
    setChatTitle('');
    setIsNewChatView(true);
    setIsAGTMakerOpen(false);
    setCurrentAGTContent('');
  }, [saveCurrentChatId]);

  const revealWordsGradually = useCallback((fullResponse, setterFunction, onComplete) => {
    const words = fullResponse.split(' ');
    let currentText = '';
    let wordIndex = 0;

    const revealInterval = setInterval(() => {
      if (wordIndex < words.length) {
        for (let i = 0; i < 5 && wordIndex < words.length; i++) {
          currentText += (wordIndex > 0 ? ' ' : '') + words[wordIndex];
          wordIndex++;
        }
        setterFunction(prevMessages => {
          const newMessages = [...prevMessages];
          newMessages[newMessages.length - 1].text = currentText;
          return newMessages;
        });
      } else {
        clearInterval(revealInterval);
        onComplete();
      }
    }, 1);

    return () => clearInterval(revealInterval);
  }, []);

  const handleNewChatInput = useCallback(async (inputMessage) => {
    if (!user || !user.uid || !inputMessage.trim() || isWaitingForResponse) return;

    setIsWaitingForResponse(true);

    try {
      let chatId = currentChatId;
      if (!chatId) {
        const newChat = await createNewChat(user.uid);
        if (!newChat || !newChat.id) {
          throw new Error('Failed to create new chat');
        }
        chatId = newChat.id;
        setCurrentChatId(chatId);
        saveCurrentChatId(chatId);
        setChatTitle("New chat");
        setChats(prevChats => [
          { ...newChat, name: "New chat" },
          ...prevChats
        ]);
      }

      const newMessage = { id: Date.now(), text: inputMessage, isUser: true, timestamp: Date.now() };
      setMessages(prevMessages => [...prevMessages, newMessage]);
      setVisibleMessages(prevMessages => [...prevMessages, newMessage]);
      setIsNewChatView(false);

      const response = await sendMessageToAGT(inputMessage, user.uid, chatId, {});
      if (!response || !response.reply) {
        throw new Error('Invalid response from AGT');
      }

      const responseMessage = { id: Date.now(), text: '', isUser: false, timestamp: Date.now() };
      setLastMessageId(responseMessage.id);
      setMessages(prevMessages => [...prevMessages, responseMessage]);
      setVisibleMessages(prevMessages => [...prevMessages, responseMessage]);

      // Actualizar lastImageRequestId si la respuesta contiene una solicitud de imagen
      if (response.reply.includes('<AGTSearch:image:')) {
        setLastImageRequestId(responseMessage.id);
      } else {
        setLastImageRequestId(null);
      }

      const cleanupReveal = revealWordsGradually(
        response.reply,
        (updater) => {
          setMessages(updater);
          setVisibleMessages(updater);
        },
        () => {
          setIsWaitingForResponse(false);
          setLastMessageId(null);
        }
      );

      setChatContext(response.updatedContext || {});
      
      if (response.title) {
        setChatTitle(response.title);
        setChats(prevChats => 
          prevChats.map(chat => 
            chat.id === chatId ? { ...chat, title: response.title, name: response.title } : chat
          ).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        );
      }
      
      chatCache.current[chatId] = { 
        messages: [newMessage, {...responseMessage, text: response.reply}], 
        context: response.updatedContext || {}
      };

      if (response.reply.split('\n').length > 15) {
        handleOpenAGTMaker(response.reply, () => {});
      }
    } catch (error) {
      console.error('Error in handleNewChatInput:', error);
      setError('Error al crear nuevo chat o enviar mensaje. Por favor, intenta de nuevo.');
      setRetryAction(() => () => handleNewChatInput(inputMessage));
    } finally {
      setIsWaitingForResponse(false);
      setIsListening(false);
      inputRef.current?.focus();
    }
  }, [user, currentChatId, sendMessageToAGT, saveCurrentChatId, revealWordsGradually, handleOpenAGTMaker]);

  const handleSendMessage = useCallback(async (messageToSend) => {
    if (!user || !user.uid || !messageToSend.trim() || isWaitingForResponse) return;
  
    setIsWaitingForResponse(true);
    setIsListening(false);
  
    try {
      if (!currentChatId) {
        await handleNewChatInput(messageToSend);
        return;
      }
  
      const newMessage = { id: Date.now(), text: messageToSend, isUser: true, timestamp: Date.now() };
      setMessages(prevMessages => [...prevMessages, newMessage]);
      setVisibleMessages(prevMessages => [...prevMessages, newMessage]);
  
      const response = await sendMessageToAGT(messageToSend, user.uid, currentChatId, chatContext);
      if (!response || !response.reply) {
        throw new Error('Invalid response from AGT');
      }

      const responseMessage = { id: Date.now(), text: '', isUser: false, timestamp: Date.now() };
      setLastMessageId(responseMessage.id);
      setMessages(prevMessages => [...prevMessages, responseMessage]);
      setVisibleMessages(prevMessages => [...prevMessages, responseMessage]);

      // Actualizar lastImageRequestId si la respuesta contiene una solicitud de imagen
      if (response.reply.includes('<AGTSearch:image:')) {
        setLastImageRequestId(responseMessage.id);
      } else {
        setLastImageRequestId(null);
      }

      const cleanupReveal = revealWordsGradually(
        response.reply, 
        (updater) => {
          setMessages(updater);
          setVisibleMessages(updater);
        },
        () => {
          setIsWaitingForResponse(false);
          setLastMessageId(null);
        }
      );
      
      setChatContext(response.updatedContext || {});
      
      chatCache.current[currentChatId] = {
        messages: [...(chatCache.current[currentChatId]?.messages || []), newMessage, {...responseMessage, text: response.reply}],
        context: response.updatedContext || {}
      };
      
      const updatedDate = new Date().toISOString();
      setChats(prevChats => 
        prevChats.map(chat => 
          chat.id === currentChatId ? { ...chat, createdAt: updatedDate } : chat
        ).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      );
  
      if (response.title) {
        setChatTitle(response.title);
        setChats(prevChats => 
          prevChats.map(chat => 
            chat.id === currentChatId ? { ...chat, title: response.title, name: response.title } : chat
          )
        );
      }

      if (response.reply.split('\n').length > 15) {
        handleOpenAGTMaker(response.reply, () => {});
      }
    } catch (error) {
      console.error('Error in handleSendMessage:', error);
      setError('Error al enviar mensaje. Por favor, intenta de nuevo.');
      setRetryAction(() => () => handleSendMessage(messageToSend));
    } finally {
      setIsWaitingForResponse(false);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  }, [
    currentChatId, 
    user, 
    chatContext, 
    isWaitingForResponse, 
    handleNewChatInput, 
    sendMessageToAGT,
    revealWordsGradually,
    handleOpenAGTMaker
  ]);

  const handleTitleEdit = useCallback(() => {
    setIsEditingTitle(true);
    setEditedTitle(chatTitle);
  }, [chatTitle]);

  const handleTitleSave = useCallback(async () => {
    if (!user || !user.uid || !currentChatId) return;
    try {
      const response = await updateChatTitle(user.uid, currentChatId, editedTitle);
      if (response && response.success) {
        setChatTitle(editedTitle);
        setChats(prevChats => 
          prevChats.map(chat => chat.id === currentChatId ? { ...chat, title: editedTitle, name: editedTitle } : chat
          ).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        );
        setIsEditingTitle(false);
        setSnackbarMessage('Chat title updated successfully');
        setSnackbarOpen(true);
      } else {
        throw new Error(response?.error || 'Failed to update chat title');
      }
    } catch (error) {
      console.error('Error in handleTitleSave:', error);
      setError('Failed to update chat title. Please try again.');
      setSnackbarMessage('Failed to update chat title. Please try again.');
      setSnackbarOpen(true);
      setRetryAction(() => handleTitleSave);
    }
  }, [user, currentChatId, editedTitle]);

  const handleGenerateSummary = useCallback(() => {
    if (!user || !user.uid || !currentChatId) return;
    dispatch(generateSummaryInBackground(user.uid, currentChatId, chatTitle));
  }, [dispatch, user, currentChatId, chatTitle]);

  useEffect(() => {
    if (summaryStatus.completed && !summaryStatus.handled) {
      const newTitle = summaryStatus.completed.title || chatTitle;
      if (newTitle !== chatTitle) {
        setChatTitle(newTitle);
        setChats(prevChats => 
          prevChats.map(chat => 
            chat.id === currentChatId ? { ...chat, name: newTitle } : chat
          )
        );
      }
      setSnackbarMessage('Nota generada con éxito');
      setSnackbarOpen(true);
      dispatch(markAsHandled({ chatId: currentChatId }));
    } else if (summaryStatus.error && !summaryStatus.handled) {
      setSnackbarMessage('Error al generar la nota');
      setSnackbarOpen(true);
      dispatch(markAsHandled({ chatId: currentChatId }));
    }
  }, [summaryStatus, currentChatId, chatTitle, dispatch]);

  const groupChatsByDate = useMemo(() => (chats) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    return chats.reduce((groups, chat) => {
      const chatDate = new Date(chat.updatedAt || chat.createdAt);
      let group;

      if (chatDate.toDateString() === today.toDateString()) {
        group = 'Today';
      } else if (chatDate.toDateString() === yesterday.toDateString()) {
        group = 'Yesterday';
      } else if ((today - chatDate) / (1000 * 60 * 60 * 24) <= 7) {
        group = 'Previous 7 Days';
      } else {
        group = 'Older';
      }

      if (!groups[group]) {
        groups[group] = [];
      }

      groups[group].push(chat);
      return groups;
    }, {});
  }, []);

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen(prevState => !prevState);
  }, []);

  const handleMenuOpen = useCallback((event, chatId) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setMenuPosition({
      top: rect.bottom,
      left: rect.right,
    });
    setMenuAnchorEl(event.currentTarget);
    setMenuChatId(chatId);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null);
    setMenuChatId(null);
  }, []);

  const handleDeleteChat = useCallback(async () => {
    if (!user || !user.uid || !menuChatId) return;
    try{
      await deleteChat(user.uid, menuChatId);
      setChats(prevChats => prevChats.filter(chat => chat.id !== menuChatId));
      if (currentChatId === menuChatId) {
        setCurrentChatId(null);
        saveCurrentChatId(null);
        setChatTitle('');
        setMessages([]);
        setVisibleMessages([]);
        setChatContext({});
        setIsNewChatView(true);
        setIsAGTMakerOpen(false);
        setCurrentAGTContent('');
      }
      delete chatCache.current[menuChatId];
      setSnackbarMessage('Chat deleted successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error in handleDeleteChat:', error);
      setError('Failed to delete chat. Please try again.');
      setSnackbarMessage('Failed to delete chat. Please try again.');
      setSnackbarOpen(true);
      setRetryAction(() => handleDeleteChat);
    } finally {
      handleMenuClose();
    }
  }, [user, menuChatId, currentChatId, handleMenuClose, saveCurrentChatId]);

  const handleVoiceTranscript = useCallback((transcript) => {
    if (inputRef.current) {
      inputRef.current.value = transcript;
    }
  }, []);

  const handleRetry = useCallback(() => {
    if (retryAction) {
      retryAction();
      setRetryAction(null);
      setError(null);
    }
  }, [retryAction]);

  useEffect(() => {
    if (!isWaitingForResponse) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  }, [isWaitingForResponse]);

  const groupedChats = useMemo(() => groupChatsByDate(chats), [chats, groupChatsByDate]);
  const orderedGroups = useMemo(() => ['Today', 'Yesterday', 'Previous 7 Days', 'Older'], []);

  const handleUpdateMessage = useCallback((messageId, newContent) => {
    setMessages(prevMessages => 
      prevMessages.map(msg => 
        msg.id === messageId ? { ...msg, text: newContent } : msg
      )
    );
    setVisibleMessages(prevMessages => 
      prevMessages.map(msg => 
        msg.id === messageId ? { ...msg, text: newContent } : msg
      )
    );
  }, []);

  const getCachedImage = useCallback((query) => {
    return imageSearchCache.current[query];
  }, []);

  const CachedInlineImageSearch = useCallback(({ onImageSelect, initialQuery }) => {
    const cachedImage = getCachedImage(initialQuery);
    
    if (cachedImage) {
      // If we have a cached result, use it immediately
      setTimeout(() => onImageSelect(cachedImage), 0);
      return null;
    }

    // If no cached result, perform the search
    return (
      <InlineImageSearch 
        onImageSelect={(selectedImage) => {
          imageSearchCache.current[initialQuery] = selectedImage;
          onImageSelect(selectedImage);
        }} 
        initialQuery={initialQuery}
      />
    );
  }, [getCachedImage]);

  const memoizedMessages = useMemo(() => {
    let messages = [...visibleMessages];
    
    if (isWaitingForResponse) {
      messages.push({ id: 'thinking', isUser: false, text: '', isLoading: true });
    }
    
    return messages.map((message, index) => {
      const isLastAssistantMessage = !message.isUser && message.id === lastMessageId;
      const hasAGTMaker = message.text && message.text.includes('<agt>');
      return (
        <MagicalMessage
          key={message.id || index}
          content={message.text}
          isUser={message.isUser}
          isLastMessage={isLastAssistantMessage}
          onFeedback={(feedback) => {
            // Implement feedback handling logic here
          }}
          userId={user.uid}
          messageId={message.id}
          hasAGTMaker={hasAGTMaker}
          onOpenAGTMaker={() => hasAGTMaker && handleOpenAGTMaker(message.text, () => {})}
          isLoading={message.isLoading}
          onUpdateMessage={handleUpdateMessage}
          lastImageRequestId={lastImageRequestId}
        />
      );
    });
  }, [visibleMessages, lastMessageId, handleOpenAGTMaker, user.uid, isWaitingForResponse, handleUpdateMessage, lastImageRequestId]);

  const renderErrorMessage = useCallback(() => {
    if (!error) return null;
    return (
      <ErrorMessage>
        <Typography color="white" gutterBottom>{error}</Typography>
        {retryAction && (
          <RetryButton
            variant="contained"
            startIcon={<RefreshCw />}
            onClick={handleRetry}
          >
            RETRY
          </RetryButton>
        )}
      </ErrorMessage>
    );
  }, [error, retryAction, handleRetry]);

  const renderSidebar = useCallback(() => (
    <Box>
      <SidebarHeader>
        <IconButton color="inherit" onClick={toggleSidebar}>
          <BgExtendIcon />
        </IconButton>
        <IconButton color="inherit" onClick={createNewChatHandler}>
          <MagicPencilIcon />
        </IconButton>
      </SidebarHeader>
      <ChatList>
        {orderedGroups.map(group => {
          if (groupedChats[group] && groupedChats[group].length > 0) {
            return (
              <React.Fragment key={group}>
                <ListItem>
                  <ListItemText 
                    primary={group} 
                    primaryTypographyProps={{
                      variant: 'overline',
                      sx: { 
                        fontWeight: 'bold',
                        color: 'rgba(255, 255, 255, 0.7)',
                        fontSize: '0.75rem',
                      }
                    }} 
                  />
                </ListItem>
                {groupedChats[group].map((chat) => (
                  chat && chat.id ? (
                    <ListItem 
                      button 
                      key={chat.id} 
                      onClick={() => selectChat(chat.id)}
                      selected={currentChatId === chat.id}
                      sx={{
                        pl: 3,
                        pr: 1,
                        backgroundColor: currentChatId === chat.id ? 'rgba(255, 255, 255, 0.1)' : 'inherit',
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        },
                        '&.Mui-selected': {
                          backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        },
                      }}
                    >
                      <Typography noWrap sx={{ color: 'white', flexGrow: 1 }}>{chat.title || "New chat"}</Typography>
                      {currentChatId === chat.id && (
                        <IconButton 
                          size="small" 
                          onClick={(event) => handleMenuOpen(event, chat.id)}
                          sx={{ color: 'white' }}
                        >
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                      )}
                    </ListItem>
                  ) : null
                ))}
              </React.Fragment>
            );
          }
          return null;
        })}
      </ChatList>
    </Box>
  ), [groupedChats, orderedGroups, currentChatId, toggleSidebar, createNewChatHandler, selectChat, handleMenuOpen]);

  const renderHeader = useCallback(() => (
    <>
      {isMobile ? (
        <MobileHeader>
          <IconButton color="inherit" onClick={toggleSidebar}>
            <BgExtendIcon />
          </IconButton>
          <Typography variant="h6" sx={{ color: 'white' }}>
            {currentChatId && !isNewChatView ? (chatTitle || "AGT Chat") : "New Chat"}
          </Typography>
          <IconButton 
            color="inherit" 
            onClick={handleGenerateSummary}
            disabled={!currentChatId || summaryStatus.inProgress || isNewChatView}
          >
            <AutoAwesomeIcon />
          </IconButton>
        </MobileHeader>
      ) : (
        <DesktopHeader>
          <Box width="33.33%" display="flex" justifyContent="flex-start">
            {!isSidebarOpen && (
              <>
                <IconButton color="inherit" onClick={toggleSidebar}>
                  <BgExtendIcon />
                </IconButton>
                <IconButton color="inherit" onClick={createNewChatHandler}>
                  <MagicPencilIcon />
                </IconButton>
              </>
            )}
          </Box>
          <Box width="33.33%" display="flex" justifyContent="center">
            {currentChatId && !isNewChatView ? (
              isEditingTitle ? (
                <TextField
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  onBlur={handleTitleSave}
                  onKeyPress={(e) => e.key === 'Enter' && handleTitleSave()}
                  autoFocus
                  variant="standard"
                  sx={{ 
                    input: { color: 'white', textAlign: 'center' },
                    '& .MuiInput-underline:before': { borderBottomColor: 'white' },
                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: 'white' },
                  }}
                />
              ) : (
                <Typography 
                  variant="h6" 
                  sx={{ 
                    color: 'white',
                    cursor: 'pointer',
                  }}
                  onClick={handleTitleEdit}
                >
                  {chatTitle}
                </Typography>
              )
            ) : (
              <Typography variant="h6" sx={{ color: 'white' }}>
                New Chat
              </Typography>
            )}
          </Box>
          <Box width="33.33%" display="flex" justifyContent="flex-end">
            {currentChatId && !isNewChatView && (
              <GenerateNoteButton
                variant="contained"
                startIcon={<AutoAwesomeIcon />}
                onClick={handleGenerateSummary}
                disabled={summaryStatus.inProgress}
              >
                {summaryStatus.inProgress ? 'Generando...' : 'Generar nota'}
              </GenerateNoteButton>
            )}
          </Box>
        </DesktopHeader>
      )}
    </>
  ), [isMobile, currentChatId, isNewChatView, chatTitle, isSidebarOpen, isEditingTitle, editedTitle, summaryStatus.inProgress, toggleSidebar, createNewChatHandler, handleGenerateSummary, handleTitleEdit, handleTitleSave]);

  return (
    <ChatContainer>
      {isMobile ? (
        <Drawer
          anchor="left"
          open={isSidebarOpen}
          onClose={toggleSidebar}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              width: 250,
            }
          }}
        >
          {renderSidebar()}
        </Drawer>
      ) : (
        <Sidebar $isOpen={isSidebarOpen}>{renderSidebar()}</Sidebar>
      )}
      
      <ChatArea 
        $isSidebarOpen={isSidebarOpen && !isMobile} 
        $isMobile={isMobile}
        className={loadingStates.messages || loadingStates.chatInfo ? 'loading' : ''}
      >
        {renderHeader()}
        
        <Box 
          display="flex" 
          flexDirection="column" 
          height="100%"
        >
          <Box flexGrow={1} overflow="auto">
            {isNewChatView || (!currentChatId && messages.length === 0) ? (
              <Box 
                display="flex" 
                flexDirection="column" 
                justifyContent="center" 
                alignItems="center" 
                height="100%"
                padding="20px"
              >
                <PromptCards onSelectPrompt={handleNewChatInput} />
              </Box>
            ) : (
              <MessagesContainer>
                {memoizedMessages}
                {renderErrorMessage()}
                <div ref={messagesEndRef} />
              </MessagesContainer>
            )}
          </Box>
          
          <MessageBarContainer>
            <InputContainer>
              {!isListening && (
                <OptimizedInput
                  onSendMessage={handleSendMessage}
                  isWaitingForResponse={isWaitingForResponse}
                  inputRef={inputRef}
                />
              )}
              <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', position: 'relative', height: '100%'}}>
                <VoiceRecognition
                  onTranscript={handleVoiceTranscript}
                  isDisabled={isWaitingForResponse}
                  inputRef={inputRef}
                  isListening={isListening}
                  setIsListening={setIsListening}
                />
              </Box>
            </InputContainer>
            <InfoText>
              By using AGT, you agree to our privacy policies.
            </InfoText>
          </MessageBarContainer>
        </Box>
      </ChatArea>
      
      <AGTMaker 
        content={currentAGTContent}
        isOpen={isAGTMakerOpen}
        onClose={handleCloseAGTMaker}
        onContentReady={handleAGTMakerContentReady}
      />
      
      <Menu
        anchorEl={menuAnchorEl}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem 
          onClick={handleDeleteChat}
          sx={{ 
            color: '#FF453A',
            '&:hover': { backgroundColor: 'rgba(255, 69, 58, 0.1)' }
          }}
        >
          <DeleteIcon sx={{ marginRight: 1 }} />
          Delete
        </MenuItem>
      </Menu>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbarOpen(false)} 
          severity={error ? "error" : "success"} 
          sx={{ width: '100%' }}
          action={
            error && retryAction ? (<Button 
              color="inherit" 
              size="small" 
              onClick={handleRetry}
              sx={{
                borderRadius: '30px',
                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                },
              }}
            >
              RETRY
            </Button>
          ) : null
        }
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  </ChatContainer>
);
});

export default ChatViewNest;