import axios from 'axios';

const AGT_API_URL = 'https://aojw2f4tm8.execute-api.us-east-2.amazonaws.com/prod';
const STANDARD_TIMEOUT = 30000; // 30 segundos en milisegundos

const api = axios.create({
  baseURL: AGT_API_URL,
  timeout: STANDARD_TIMEOUT,
  withCredentials: true
});

// Interceptor para manejar errores globalmente
api.interceptors.response.use(
  response => response,
  error => {
    console.error('API Error:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
    }
    return Promise.reject(error);
  }
);

export const getChats = async (userId) => {
  try {
    const response = await api.get(`/api/chats/${userId}`);
    return Array.isArray(response.data.chats) 
      ? response.data.chats.filter(chat => chat && chat.id).map(chat => ({
          ...chat,
          title: chat.title || chat.name || "New chat"
        }))
      : [];
  } catch (error) {
    console.error('Failed to fetch chats:', error);
    return [];
  }
};

export const getChatMessages = async (userId, chatId) => {
  try {
    if (!chatId) {
      return [];
    }
    const response = await api.get(`/api/chat-messages/${userId}/${chatId}`);
    return response.data.messages || [];
  } catch (error) {
    console.error('Failed to fetch chat messages:', error);
    return [];
  }
};

export const sendMessageToAGT = async (message, userId, chatId, context) => {
  try {
    const endpoint = chatId ? '/api/chat' : '/api/create-chat';
    const payload = chatId 
      ? { message, userId, chatId, context }
      : { userId, message, context };

    const response = await api.post(endpoint, payload);
    
    return {
      reply: response.data.reply,
      updatedContext: response.data.updatedContext,
      title: response.data.title,
      chatId: response.data.chatId || (response.data.chat && response.data.chat.id)
    };
  } catch (error) {
    console.error('Failed to send message to AGT:', error);
    if (error.code === 'ECONNABORTED') {
      throw new Error('La solicitud ha excedido el tiempo de espera. Por favor, inténtalo de nuevo.');
    }
    throw new Error('Failed to send message. Please try again.');
  }
};

export const createNewChat = async (userId) => {
  try {
    const response = await api.post('/api/create-chat', { userId });
    return {
      id: response.data.chat.id,
      title: response.data.chat.title || "New chat",
      name: response.data.chat.name || "New chat",
      createdAt: response.data.chat.createdAt
    };
  } catch (error) {
    console.error('Failed to create new chat:', error);
    throw new Error('Failed to create new chat. Please try again.');
  }
};

export const getChatContext = async (userId, chatId) => {
  try {
    if (!chatId) {
      return null;
    }
    const response = await api.get(`/api/chat-context/${userId}/${chatId}`);
    return response.data.context;
  } catch (error) {
    console.error('Failed to fetch chat context:', error);
    return null;
  }
};

export const getAnonymousChatHistory = async (sessionId) => {
  try {
    const response = await api.get(`/api/anonymous-chat/${sessionId}`);
    return response.data.messages || [];
  } catch (error) {
    console.error('Failed to fetch anonymous chat history:', error);
    return [];
  }
};

export const getChatHistory = async (userId) => {
  try {
    const response = await api.get(`/api/chat-history/${userId}`);
    return response.data.messages || [];
  } catch (error) {
    console.error('Failed to fetch chat history:', error);
    return [];
  }
};

export const getSummaries = async (userId, page = 1, limit = 10) => {
  try {
    const response = await api.get(`/api/summaries/${userId}`, {
      params: { page, limit }
    });
    return {
      summaries: Array.isArray(response.data.summaries) ? response.data.summaries : [],
      totalPages: response.data.totalPages,
      currentPage: response.data.currentPage
    };
  } catch (error) {
    console.error('Error fetching summaries:', error);
    return { summaries: [], totalPages: 0, currentPage: 1 };
  }
};

export const searchSummaries = async (userId, query) => {
  try {
    const response = await api.get(`/api/summaries/${userId}/search`, {
      params: { query }
    });
    return {
      summaries: Array.isArray(response.data.summaries) ? response.data.summaries : [],
      total: response.data.total
    };
  } catch (error) {
    console.error('Error searching summaries:', error);
    return { summaries: [], total: 0 };
  }
};

export const editSummary = async (userId, timestamp, editedSummary, color = null) => {
  try {
    const response = await api.put('/api/edit-summary', {
      userId,
      timestamp,
      editedSummary,
      color
    });
    return response.data;
  } catch (error) {
    console.error('Failed to edit summary:', error);
    throw new Error('Failed to edit summary');
  }
};

export const closeNote = async (userId, timestamp) => {
  try {
    const response = await api.put('/api/close-note', {
      userId,
      timestamp
    });
    return response.data;
  } catch (error) {
    console.error('Failed to close note:', error);
    throw new Error('Failed to close note');
  }
};

export const deleteNote = async (userId, timestamp) => {
  try {
    const response = await api.delete('/api/delete-note', {
      data: { userId, timestamp }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to delete note:', error);
    throw new Error('Failed to delete note');
  }
};

export const getConversation = async (userId, timestamp, createdAt, lastMessageTimestamp) => {
  try {
    if (!createdAt || !lastMessageTimestamp) {
      throw new Error('createdAt and lastMessageTimestamp are required');
    }
    
    const response = await api.get(`/api/conversation/${userId}/${timestamp}`, {
      params: { 
        createdAt: new Date(createdAt).toISOString(),
        lastMessageTimestamp: new Date(lastMessageTimestamp).toISOString()
      }
    });
    
    return response.data.conversation;
  } catch (error) {
    console.error('Failed to fetch conversation:', error);
    throw new Error(`Failed to fetch conversation: ${error.message}`);
  }
};

export const updateChatTitle = async (userId, chatId, newTitle) => {
  try {
    const response = await api.put('/api/update-chat-title', {
      userId,
      chatId,
      newTitle
    });
    return response.data;
  } catch (error) {
    console.error('Failed to update chat title:', error);
    throw new Error('Failed to update chat title');
  }
};

export const generateChatSummary = async (userId, chatId, generateSummary = false, chatTitle) => {
  try {
    const response = await api.post('/api/generate-summary', {
      userId,
      chatId,
      generateSummary,
      chatTitle,
      isFinal: false
    });
    return response.data;
  } catch (error) {
    console.error('Failed to generate chat summary:', error);
    throw new Error('Failed to generate chat summary');
  }
};

export const deleteChat = async (userId, chatId) => {
  try {
    const response = await api.delete(`/api/delete-chat/${userId}/${chatId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to delete chat:', error);
    throw new Error('Failed to delete chat');
  }
};

export const sendFeedback = async (userId, messageId, feedbackType, details, reason) => {
  if (!userId || !messageId || !feedbackType) {
    throw new Error('userId, messageId, and feedbackType are required');
  }

  try {
    const response = await api.post('/api/feedback', {
      userId,
      messageId,
      feedbackType,
      details,
      reason
    });
    return response.data;
  } catch (error) {
    console.error('Error sending feedback:', error);
    throw new Error('Failed to send feedback');
  }
};

export const setUserBackgroundPreference = async (userId, backgroundType) => {
  try {
    const response = await api.put('/api/user-background', { userId, backgroundType });
    return response.data;
  } catch (error) {
    console.error('Failed to set user background preference:', error);
    throw new Error('Failed to set user background preference');
  }
};

export const getUserBackgroundPreference = async (userId) => {
  try {
    const response = await api.get(`/api/user-background/${userId}`);
    return response.data.backgroundType;
  } catch (error) {
    console.error('Failed to get user background preference:', error);
    throw new Error('Failed to get user background preference');
  }
};

export default {
  getChats,
  getChatMessages,
  sendMessageToAGT,
  createNewChat,
  getChatContext,
  getAnonymousChatHistory,
  getChatHistory,
  getSummaries,
  searchSummaries,
  editSummary,
  closeNote,
  deleteNote,
  getConversation,
  updateChatTitle,
  generateChatSummary,
  deleteChat,
  sendFeedback,
  setUserBackgroundPreference,
  getUserBackgroundPreference
};