import { createSlice, configureStore } from '@reduxjs/toolkit';
import { generateChatSummary } from './AGTService';

const summarySlice = createSlice({
  name: 'summary',
  initialState: {
    inProgress: {},
    completed: {},
    errors: {},
    handled: {}
  },
  reducers: {
    startSummary: (state, action) => {
      state.inProgress[action.payload.chatId] = true;
      state.handled[action.payload.chatId] = false;
      // Limpiar cualquier resultado o error anterior
      delete state.completed[action.payload.chatId];
      delete state.errors[action.payload.chatId];
    },
    completeSummary: (state, action) => {
      delete state.inProgress[action.payload.chatId];
      state.completed[action.payload.chatId] = action.payload.summary;
      state.handled[action.payload.chatId] = false;
    },
    summaryError: (state, action) => {
      delete state.inProgress[action.payload.chatId];
      state.errors[action.payload.chatId] = action.payload.error;
      state.handled[action.payload.chatId] = false;
    },
    markAsHandled: (state, action) => {
      state.handled[action.payload.chatId] = true;
    }
  }
});

export const { startSummary, completeSummary, summaryError, markAsHandled } = summarySlice.actions;

export const store = configureStore({
  reducer: {
    summary: summarySlice.reducer
  }
});

export const generateSummaryInBackground = (userId, chatId, chatTitle) => async (dispatch) => {
  dispatch(startSummary({ chatId }));
  try {
    const result = await generateChatSummary(userId, chatId, true, chatTitle);
    dispatch(completeSummary({ chatId, summary: result }));
    // Aquí podrías enviar una notificación al usuario
    return result; // Retornamos el resultado para que pueda ser usado en el componente si es necesario
  } catch (error) {
    dispatch(summaryError({ chatId, error: error.message }));
    // Aquí podrías enviar una notificación de error al usuario
    throw error; // Re-lanzamos el error para que pueda ser manejado en el componente si es necesario
  }
};

export const selectSummaryStatus = (state, chatId) => ({
  inProgress: state.summary.inProgress[chatId] || false,
  completed: state.summary.completed[chatId] || null,
  error: state.summary.errors[chatId] || null,
  handled: state.summary.handled[chatId] || false
});

// Función auxiliar para limpiar el estado del resumen para un chat específico
export const clearSummaryStatus = (chatId) => (dispatch) => {
  dispatch(summarySlice.actions.startSummary({ chatId }));
  dispatch(summarySlice.actions.markAsHandled({ chatId }));
};

// Función auxiliar para resetear todo el estado de los resúmenes
export const resetAllSummaryStatuses = () => (dispatch) => {
  dispatch(summarySlice.actions.resetState());
};

// Añadimos un reducer adicional para resetear todo el estado
summarySlice.actions.resetState = () => {
  return {
    inProgress: {},
    completed: {},
    errors: {},
    handled: {}
  };
};

export default store;